import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { customObjectsQueryKeys } from '@amalia/data-capture/records/state';

import { CustomObjectDefinitionsApiClient } from '../api-client/custom-object-definitions.api-client';

import { customObjectDefinitionsMutationKeys, customObjectDefinitionsQueryKeys } from './queries.keys';

export const useRealCustomObjectDefinitions = () =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.listReal(),
    queryFn: CustomObjectDefinitionsApiClient.list,
  });

export const useVirtualCustomObjectDefinitions = () =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.listVirtual(),
    queryFn: CustomObjectDefinitionsApiClient.listVirtual,
  });

export const useVirtualCustomObjectDefinition = (machineName: string | null) =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.details(machineName!),
    queryFn: () => CustomObjectDefinitionsApiClient.getOneVirtual(machineName!),
    enabled: !!machineName,
  });

export const useCustomObjectDefinitions = () => {
  const realResult = useRealCustomObjectDefinitions();
  const virtualResult = useVirtualCustomObjectDefinitions();

  return useMemo(
    () => ({
      data: [...(realResult.data || []), ...(virtualResult.data || [])],
      isError: realResult.isError || virtualResult.isError,
    }),
    [realResult, virtualResult],
  );
};

export const useDeleteCustomObjectDefinition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: customObjectDefinitionsMutationKeys.delete(),
    mutationFn: (args: Parameters<(typeof CustomObjectDefinitionsApiClient)['delete']>) =>
      CustomObjectDefinitionsApiClient.delete(...args),
    onSuccess: async (_, [objectDefinition]) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: customObjectDefinitionsQueryKeys.all() }),
        queryClient.invalidateQueries({
          queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinition.machineName),
        }),
      ]);
    },
  });
};
