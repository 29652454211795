import { combineReducers } from 'redux';

import { PaymentCategory } from '@amalia/core/types';

import { type ReduxAction } from '../types';

import { PAYMENTS_ACTIONS } from './constants';
import { type PaymentsReducer } from './types';

export const paymentsReducer = combineReducers<PaymentsReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.START:
        return state + 1;
      case PAYMENTS_ACTIONS.ERROR:
      case PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS:
      case PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS:
      case PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS:
      case PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS:
        return state - 1;
      default:
        return state;
    }
  },
  // @ts-expect-error -- Shit's broken.
  listByCategoryForCurrentStatement: (
    state = {
      achievement: [],
      hold: [],
      paid: [],
      adjustments: [],
    },
    action,
  ) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS:
        return action.payload.payments;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          [PaymentCategory.achievement]: [],
          [PaymentCategory.hold]: [],
          [PaymentCategory.paid]: [],
          [PaymentCategory.adjustments]: [],
        };
      default:
        return state;
    }
  },
  // @ts-expect-error -- Shit's broken.
  contextualizedPayments: (state = [], action) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS:
        return action.payload.payments;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return [];
      default:
        return state;
    }
  },
  // @ts-expect-error -- Shit's broken.
  amountByCategoryForCurrentStatement: (
    state = {
      [PaymentCategory.achievement]: null,
      [PaymentCategory.hold]: null,
      [PaymentCategory.paid]: null,
      [PaymentCategory.adjustments]: null,
      containsHoldRules: false,
    },
    action,
  ) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS:
        return action.payload.paymentAmounts;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          [PaymentCategory.achievement]: null,
          [PaymentCategory.hold]: null,
          [PaymentCategory.paid]: null,
          [PaymentCategory.adjustments]: null,
          containsHoldRules: false,
        };
      default:
        return state;
    }
  },
  amountsByCategoryForCompany: (state = {}, action) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS:
        return action.payload.companyAmounts;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          [PaymentCategory.achievement]: [],
          [PaymentCategory.hold]: [],
          [PaymentCategory.paid]: [],
          [PaymentCategory.adjustments]: [],
          containsHoldRules: false,
        };
      default:
        return state;
    }
  },
  currentPaymentCategory: (state = PaymentCategory.achievement, action) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_PAYMENT_CATEGORY:
        return action.payload.category;
      default:
        return state;
    }
  },
});
