import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { assert } from '@amalia/ext/typescript';

import {
  applyOverwriteToComputedStatement,
  removeOverwriteFromComputedStatement,
} from '../../services/statements/statements.service';
import { PLANS_ACTIONS as PLAN_ACTIONS } from '../plans/constants';
import { type ReduxAction } from '../types';

import { STATEMENTS_ACTIONS } from './constants';
import { type StatementsReducer } from './types';

export const statementsReducer = combineReducers<StatementsReducer, ReduxAction>({
  isLoading: (state = 0, action): number => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.START:
        return state + 1;
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS:
      case STATEMENTS_ACTIONS.CREATE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.DELETE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.EDIT_ADJUSTMENT:
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
      case STATEMENTS_ACTIONS.CREATE_OVERWRITE:
      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE:
      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE:
      case STATEMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  lastparams: (state = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return action.payload.options;
      default:
        return state;
    }
  },
  map: (state = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return keyBy(action.payload.statements, 'id');
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
        return {
          ...state,
          [action.payload.statementId]: {
            ...state[action.payload.statementId],
            workflowSteps: action.payload.updatedStatement.workflowSteps,
            workflowComplete: action.payload.updatedStatement.workflowComplete,
          },
        };
      default:
        return state;
    }
  },
  userStatements: (state = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return {};
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
        return keyBy(action.payload.userStatements, 'id');
      default:
        return state;
    }
  },
  currentPeriod: (state = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS:
        return action.payload.currentPeriod.id === state?.id ? state : action.payload.currentPeriod;
      default:
        return state;
    }
  },
  currentStatement: (state = null, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return null;

      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
        return action.payload.statement;

      case STATEMENTS_ACTIONS.CLEAR_STATEMENT:
        return null;

      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP: {
        assert(state, 'currentStatement is not defined');
        return {
          ...state,
          workflowSteps: action.payload.updatedStatement.workflowSteps,
          workflowComplete: action.payload.updatedStatement.workflowComplete,
        };
      }

      case STATEMENTS_ACTIONS.CREATE_OVERWRITE: {
        assert(state, 'currentStatement is not defined');
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      }

      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE: {
        assert(state, 'currentStatement is not defined');
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };
      }

      case STATEMENTS_ACTIONS.CREATE_ADJUSTMENT:
        return state && state.id === action.payload.statementId
          ? {
              ...state,
              adjustments: [...(state.adjustments || []), action.payload.adjustment],
            }
          : state;

      case STATEMENTS_ACTIONS.DELETE_ADJUSTMENT:
        return state?.id === action.payload.statementId
          ? {
              ...state,
              adjustments: (state.adjustments || []).filter(
                (adjustment) => adjustment.id !== action.payload.adjustmentId,
              ),
            }
          : state;

      case STATEMENTS_ACTIONS.EDIT_ADJUSTMENT:
        return state?.id === action.payload.statementId
          ? {
              ...state,
              adjustments: (state.adjustments || []).map((adjustment) =>
                adjustment.id === action.payload.adjustment.id ? action.payload.adjustment : adjustment,
              ),
            }
          : state;

      default:
        return state;
    }
  },

  currentForecastedStatement: (state = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
        return action.payload.forecastedStatement;

      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE: {
        assert(state, 'currentForecastedStatement is not defined');
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      }

      case STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE: {
        assert(state, 'currentForecastedStatement is not defined');
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };
      }

      default:
        return state;
    }
  },
});
