import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { type Company } from '@amalia/tenants/companies/types';

import { type Challenge } from './challenges';

export enum BadgeConfigurationType {
  RANKING = 'RANKING',
  KPI = 'KPI',
}

export enum BadgeConfigurationIcon {
  BEST_DEAL = 'BEST_DEAL',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  INTERNATIONAL = 'INTERNATIONAL',
  OVER_PERFORMER = 'OVER_PERFORMER',
  STAR = 'STAR',
  TEAM_PLAYER = 'TEAM_PLAYER',
  WINNER = 'WINNER',
  WIN_RATE = 'WIN_RATE',
}

export interface BadgeConfiguration {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;
  companyId: string;

  name: string;
  description: string | null;

  type: BadgeConfigurationType;
  icon: BadgeConfigurationIcon;

  planId: string;

  variable?: Variable | null;
  variableId: string | null;

  challenge?: Challenge | null;
  challengeId: string | null;

  deletedAt: Date | null;
}

export interface BadgeAward {
  id?: string;

  userId: string;

  periodId: string;

  statementId: string;

  configuration: BadgeConfiguration;
  configurationId: string;
}
