import {
  CustomPaging,
  EditingState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  type Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnVisibility,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { IconPin, IconPinnedOff } from '@tabler/icons-react';
import { memo, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import store from 'store';

import { IconButton } from '@amalia/design-system/components';

import { type DataGridProps } from './Datagrid.props';
import {
  DataGridPlugins,
  type DataGridPluginDefinition,
  DataGridPluginPosition,
} from './DataGridComponents/DataGridPlugin';
import { DataGridShowColumnsButton } from './DataGridComponents/DataGridShowColumnsButton';
import {
  DatagridSearchInput,
  InitialWidthTableComponent,
  TableComponentBase,
  TableGroupCell,
  TableHeaderCell,
  TableHeaderContent,
} from './DataGridComponents/NativeComponents';
import { PageChooser } from './DataGridComponents/PageChooser';
import { useDatagridStyles } from './datagridStyles';

export const DataGrid = memo(function DataGrid({
  id,
  columns,
  rows,
  tableProps,
  children,
  options,
  useVirtualTable = false,
  withHeight,
}: DataGridProps) {
  const classes = useDatagridStyles();
  const { formatMessage } = useIntl();
  const TableComp = useMemo(() => (useVirtualTable ? VirtualTable : Table), [useVirtualTable]);
  // SORTING
  const [sorting, setSorting] = useState<Sorting[]>(
    (store.get(`datagrid_${id}_sorting`) as Sorting[] | undefined) || options?.sort?.defaultSorting || [],
  );
  useEffect(() => {
    if (!options?.sort?.overwriteSort) {
      store.set(`datagrid_${id}_sorting`, sorting);
    }
  }, [sorting, id, options]);

  // PAGINABLE
  const [currentPage, setCurrentPage] = useState(0);

  const pageSizes = useMemo(() => options?.pages?.overridePageSizeOptions || [5, 10, 20, 50, 100], [options]);
  const [pageSize, setPageSize] = useState<number>((store.get(`datagrid_${id}_pageSize`) as number) || 10);
  useEffect(() => {
    store.set(`datagrid_${id}_pageSize`, pageSize);
  }, [pageSize, id]);

  const messages = useMemo(
    () => ({
      SearchPanel: {
        searchPlaceholder: formatMessage({ defaultMessage: 'Search' }),
      },
      PaginationPanel: {
        showAll: formatMessage({ defaultMessage: 'Show all' }),
        rowsPerPage: formatMessage({ defaultMessage: 'Rows per page' }),
        info: ({ from, to, count }: { from: number; to: number; count: number }) =>
          formatMessage({ defaultMessage: '{from, number}-{to, number} of {count, number}' }, { from, to, count }),
      },
      TableHeaderRow: {
        sortingHint: formatMessage({ defaultMessage: 'Sort' }),
      },
    }),
    [formatMessage],
  );

  // Customized Paging panel
  const customizedPagingPanel = useMemo(() => {
    const pageLength = (options?.pages?.customPagingTotalItems || rows.length) / (options?.pages?.pageSize || pageSize);
    return (
      <PagingPanel
        messages={messages.PaginationPanel}
        pageSizes={pageSizes}
        containerComponent={(props: PropsWithChildren<PagingPanel.ContainerProps>) => (
          <div
            className={classes.pagingPanelContainer}
            id={`${id}-pagination`}
          >
            <PagingPanel.Container {...props}>{props.children}</PagingPanel.Container>
            {/* If we have less than 5 pages, hide page chooser */}
            {pageLength > 5 && (
              <PageChooser
                currentPage={options?.pages?.currentPage || currentPage}
                setCurrentPage={(val: number) => {
                  (options?.pages?.onCurrentPageChange || setCurrentPage)(val);
                }}
              />
            )}
          </div>
        )}
      />
    );
  }, [
    options?.pages?.customPagingTotalItems,
    options?.pages?.pageSize,
    options?.pages?.currentPage,
    options?.pages?.onCurrentPageChange,
    rows.length,
    pageSize,
    pageSizes,
    messages.PaginationPanel,
    classes.pagingPanelContainer,
    id,
    currentPage,
  ]);

  // SEARCH
  const [searchValue, setSearchValue] = useState<string>((store.get(`datagrid_${id}_searchValue`) as string) || '');
  useEffect(() => {
    store.set(`datagrid_${id}_searchValue`, searchValue);
  }, [searchValue, id]);

  // HIDDEN COLUMNS
  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(
    (store.get(`datagrid_${id}_hiddenColumns`) as string[] | undefined) || [],
  );
  useEffect(() => {
    store.set(`datagrid_${id}_hiddenColumns`, hiddenColumnNames);
  }, [hiddenColumnNames, id]);

  useEffect(() => {
    if (currentPage > rows.length / pageSize) {
      setCurrentPage(0);
    }
  }, [rows, pageSize, currentPage]);

  // FIXED COLUMNS
  const [isFirstColumnPinned, setIsFirstColumnPinned] = useState<boolean>(
    !!options?.fixed?.pinnableFirstColumn && !!options.fixed.pinnedFirstColumnComponent,
  );
  const toggleFirstColumnPinned = useCallback(() => setIsFirstColumnPinned((p) => !p), []);

  const fixedColumns = useMemo(() => {
    // Computing which columns are not hidden, both from the selector and the initial options
    const notHiddenColumns = columns.filter(
      (c) =>
        !hiddenColumnNames.includes(c.name) && !(options?.columnVisibility?.hiddenColumnNames || []).includes(c.name),
    );
    // Fix the first not hidden column
    return [...(options?.fixed?.fixedColumns || []), isFirstColumnPinned ? notHiddenColumns[0]?.name || '' : ''];
  }, [columns, isFirstColumnPinned, hiddenColumnNames, options]);

  // HEADER COMPONENTS
  const headerComponents: DataGridPluginDefinition[] = useMemo(() => {
    const pinLabel = isFirstColumnPinned
      ? formatMessage({ defaultMessage: 'Unpin first column' })
      : formatMessage({ defaultMessage: 'Pin first column' });
    return [
      ...(options?.fixed?.pinnableFirstColumn
        ? [
            {
              key: 'pinnableFirstColumn',
              children: (
                <IconButton
                  icon={isFirstColumnPinned ? <IconPinnedOff /> : <IconPin />}
                  label={pinLabel}
                  onClick={toggleFirstColumnPinned}
                />
              ),
              position: DataGridPluginPosition.rightEnd,
            },
          ]
        : []),
      ...(options?.additionalHeaderComponents || []).map((ahc) => ({
        key: ahc.key,
        children: ahc.children,
        position: ahc.position,
      })),
    ];
  }, [
    options?.additionalHeaderComponents,
    isFirstColumnPinned,
    options?.fixed?.pinnableFirstColumn,
    toggleFirstColumnPinned,
    formatMessage,
  ]);

  return (
    <div className={classes.customGridContainer}>
      <Grid
        columns={columns}
        getCellValue={options?.getCellValue}
        getRowId={options?.getRowId}
        rows={rows}
      >
        {!options?.dontRenderToolbar && <Toolbar />}

        {options?.sort?.sortable ? (
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
            {...options.sort}
          />
        ) : null}

        {options?.search?.searchable ? (
          <SearchState
            value={searchValue}
            onValueChange={setSearchValue}
            {...options.search}
          />
        ) : null}
        {options?.search?.searchable && !options.dontRenderToolbar ? (
          <SearchPanel
            inputComponent={DatagridSearchInput}
            messages={messages.SearchPanel}
          />
        ) : null}
        {options?.search?.searchable && !options.search.overwriteSearch ? <IntegratedFiltering /> : null}

        {options?.grouping ? (
          <GroupingState
            defaultExpandedGroups={options.grouping.defaultExpandedGroups}
            grouping={options.grouping.grouping}
            onExpandedGroupsChange={options.grouping.onExpandedGroupsChange}
          />
        ) : null}
        {options?.grouping ? <IntegratedGrouping /> : null}

        {options?.sort?.sortable && !options.sort.overwriteSort ? (
          <IntegratedSorting columnExtensions={options.sort.integratedColumnExtensions} />
        ) : null}

        {options?.pages?.paginable && pageSize ? (
          <PagingState
            currentPage={currentPage}
            pageSize={pageSize}
            onCurrentPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
            {...options.pages}
          />
        ) : null}
        {options?.pages?.paginable && Number.isFinite(options.pages.customPagingTotalItems) ? (
          <CustomPaging totalCount={options.pages.customPagingTotalItems} />
        ) : null}

        {options?.pages?.paginable && pageSize && !options.pages.customPagingTotalItems ? <IntegratedPaging /> : null}
        {options?.pages?.paginable && pageSize ? customizedPagingPanel : null}

        <TableComp
          {...(withHeight ? { height: withHeight } : {})}
          tableComponent={options?.fullWidth ? TableComponentBase : InitialWidthTableComponent}
          {...tableProps}
        />

        {options?.columnVisibility?.active ? (
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
            {...options.columnVisibility}
          />
        ) : null}
        {options?.columnVisibility?.active && !options.columnVisibility.disableColumnChooser ? (
          <ColumnChooser toggleButtonComponent={DataGridShowColumnsButton} />
        ) : null}

        {options?.cellEdit?.active && options.cellEdit.onCommitChanges ? (
          <EditingState
            columnExtensions={options.cellEdit.columnExtensions || []}
            createRowChange={options.cellEdit.createRowChange}
            defaultEditingRowIds={options.cellEdit.defaultEditingRowIds || []}
            onCommitChanges={options.cellEdit.onCommitChanges}
          />
        ) : null}

        {options?.cellEdit?.active && options.cellEdit.onCommitChanges ? (
          <TableInlineCellEditing
            selectTextOnEditStart
            startEditAction="click"
          />
        ) : null}

        {children}

        {/* @ts-expect-error -- idgaf */}
        {options?.grouping ? <TableGroupRow cellComponent={options.tableGroupRowComponent || TableGroupCell} /> : null}

        <TableHeaderRow
          cellComponent={options?.tableHeaderRowComponent || TableHeaderCell}
          contentComponent={TableHeaderContent}
          messages={messages.TableHeaderRow}
          showSortingControls={options?.sort?.sortable ? !options.sort.disableSortingControls : undefined}
        />
        {fixedColumns.length && options?.fixed?.pinnedFirstColumnComponent ? (
          <TableFixedColumns
            cellComponent={options.fixed.pinnedFirstColumnComponent}
            leftColumns={fixedColumns}
          />
        ) : null}
        <DataGridPlugins plugins={Object.values(headerComponents)} />
      </Grid>
    </div>
  );
});
