import { http } from '@amalia/core/http/client';
import { type UpdateAdjustmentRequest, type Adjustment, type CreateAdjustmentRequest } from '@amalia/core/types';

export class StatementAdjustmentApiClient {
  public static async createStatementAdjustment(
    statementId: string,
    adjustment: Omit<CreateAdjustmentRequest, 'statementId'>,
  ): Promise<Adjustment> {
    const { data } = await http.post<Adjustment>('/statements_adjustments', {
      statementId,
      ...adjustment,
    });

    return data;
  }

  public static async editStatementAdjustment(
    adjustment: UpdateAdjustmentRequest & { id: Adjustment['id'] },
  ): Promise<Adjustment> {
    const { data } = await http.patch<Adjustment>(`/statements_adjustments/${adjustment.id}`, adjustment);
    return data;
  }

  public static async deleteStatementAdjustment(adjustmentId: string): Promise<void> {
    await http.delete(`statements_adjustments/${adjustmentId}`);
  }
}
