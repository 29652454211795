import { type OverwriteResponse, OverwriteTypesEnum, type PaginatedResponse } from '@amalia/core/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import { assert, toError } from '@amalia/ext/typescript';
import { PaymentApiClient } from '@amalia/payout-calculation/payments/state';
import { StatementsApiClient } from '@amalia/payout-calculation/statements/state';

import { getOverwrites } from '../../services/overwrites/overwrites.repository';
import { type OverwriteFilters } from '../../types/overwrites';
import { type ThunkResult } from '../types';

import { OVERWRITES_ACTIONS } from './constants';
import { selectPageOptions } from './selectors';
import {
  type OverwritesClearOverwriteAction,
  type OverwritesErrorAction,
  type OverwritesSetOverwritesAction,
  type OverwritesStartAction,
  type PaginationOptions,
} from './types';

const overwritesStart = (): OverwritesStartAction => ({
  type: OVERWRITES_ACTIONS.START,
});

const overwritesError = (error: Error): OverwritesErrorAction => ({
  type: OVERWRITES_ACTIONS.ERROR,
  error,
});

const setList = (
  list: PaginatedResponse<Partial<OverwriteResponse>>,
  pageOptions: PaginationOptions,
): OverwritesSetOverwritesAction => ({
  type: OVERWRITES_ACTIONS.SET_OVERWRITES,
  payload: { list, pageOptions },
});

const clearOverwriteAction = (id: string): OverwritesClearOverwriteAction => ({
  type: OVERWRITES_ACTIONS.CLEAR_OVERWRITE,
  payload: { id },
});

export const fetchOverwrites =
  (
    searchText?: string,
    page = 0,
    limit = 10,
    sortColumn?: string,
    sortOrder = 'asc',
    selectedFilters?: OverwriteFilters,
  ): ThunkResult<Promise<OverwritesErrorAction | OverwritesSetOverwritesAction>> =>
  async (dispatch) => {
    dispatch(overwritesStart());
    try {
      const list = await getOverwrites(searchText, page, limit, sortColumn, sortOrder, selectedFilters);
      return dispatch(
        setList(list, {
          searchText,
          page,
          limit,
          sortColumn,
          sortOrder,
          selectedFilters,
        }),
      );
    } catch (error) {
      return dispatch(overwritesError(toError(error)));
    }
  };

export const clearOverwrite =
  (overwrite: OverwriteResponse): ThunkResult<Promise<OverwritesClearOverwriteAction | OverwritesErrorAction>> =>
  async (dispatch, getState) => {
    dispatch(overwritesStart());
    const { selectedFilters, searchText, page, limit, sortColumn, sortOrder } = selectPageOptions(getState());

    try {
      switch (overwrite.overwriteType) {
        case OverwriteTypesEnum.PAYMENT: {
          assert(overwrite.paymentId, 'Payment ID is required to clear payment overwrite');
          await PaymentApiClient.clearPaymentOverwrite(overwrite.paymentId, {
            overwriteId: overwrite.id,
          });
          break;
        }
        case OverwriteTypesEnum.FIELD:
        case OverwriteTypesEnum.KPI:
        case OverwriteTypesEnum.FILTER_ROW_REMOVE: {
          const statementId = overwrite.statementId || overwrite.createdOnStatementId;
          assert(statementId, 'Statement ID is required to clear statement overwrite');
          await StatementsApiClient.clearStatementOverwrite(statementId, overwrite.id);
          break;
        }
        case OverwriteTypesEnum.PROPERTY: {
          assert(
            overwrite.customObjectDefinitionMachineName,
            'Definition machine name is required to clear property overwrite',
          );
          assert(overwrite.appliesToExternalId, 'External ID is required to clear property overwrite');
          await CustomObjectsApiClient.clearCustomObject({
            definitionMachineName: overwrite.customObjectDefinitionMachineName,
            objectExternalId: overwrite.appliesToExternalId,
            overwriteId: overwrite.id,
          });
          break;
        }
        default:
          break;
      }

      await dispatch(fetchOverwrites(searchText, page, limit, sortColumn, sortOrder, selectedFilters));
      return dispatch(clearOverwriteAction(overwrite.id));
    } catch (error) {
      return dispatch(overwritesError(toError(error)));
    }
  };
