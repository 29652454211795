import { useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Plan, type Rule } from '@amalia/payout-definition/plans/types';

import { rulesV2MutationKeys, rulesV2QueryKeys } from '../queries.keys';

import { RulesApiClient } from './rules.api-client';

export const useRulePlanConfiguration = (planId: Plan['id'], ruleId: Rule['id']) =>
  useQuery({
    queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId),
    queryFn: () => RulesApiClient.getRuleConfiguration(planId, ruleId),
  });

export const useUpdateRuleAssignment = ({ ignoreSnack }: { ignoreSnack: boolean } = { ignoreSnack: false }) => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: rulesV2MutationKeys.configurations.updateRuleAssignment(),
    mutationFn: RulesApiClient.updateRuleAssignment,
    onSuccess: async () => {
      if (!ignoreSnack) {
        snackSuccess(formatMessage({ defaultMessage: 'Rule updated successfully.' }));
      }
      await queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.all() });
    },
  });
};

export const useIsRuleAssignmentLoading = (planId: string): boolean => {
  const isFetching = useIsFetching({
    queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId),
  });

  const isMutating = useIsMutating({
    mutationKey: rulesV2MutationKeys.configurations.updateRuleAssignment(),
  });

  return !!isFetching || !!isMutating;
};
