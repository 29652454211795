import {
  IconAddressBook,
  IconCash,
  IconChartBar,
  IconDatabase,
  IconFileDescription,
  IconHome,
  IconLicense,
  IconListSearch,
  IconRadar,
  IconRuler,
  IconTable,
  IconUserCircle,
  IconUsers,
  IconViewfinder,
  IconWriting,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { fetchPlans, selectPlans, useFeatureFlag, useThunkDispatch } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, subject, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';

import { type NavigationItems, type NavigationItemType } from '../../Layout.types';

export type PageIdentifier =
  | 'audit'
  | 'companySettings'
  | 'customReport'
  | 'dashboard'
  | 'dashboardsV2'
  | 'data'
  | 'designer'
  | 'directory'
  | 'flow'
  | 'forecasts'
  | 'home'
  | 'overwrites'
  | 'plan-agreements'
  | 'plans'
  | 'quotas'
  | 'reporting'
  | 'statements'
  | 'teams';

type NavigationItemWithAccessControl = Omit<NavigationItemType, 'isSelected'> & {
  identifier: PageIdentifier;
  isVisible: boolean;
};

export const useNavigationItems = (currentPage?: PageIdentifier): { navigationItems: NavigationItems } => {
  const ability = useAbilityContext();

  const { isFeatureEnabled: isDashboardsV2FFEnabled } = useFeatureFlag(CompanyFeatureFlags.CUSTOM_DASHBOARDS);
  const { isFeatureEnabled: isForecastFFEnabled } = useFeatureFlag(CompanyFeatureFlags.FORECAST);
  const { isFeatureEnabled: isPlanAgreementsFFEnabled } = useFeatureFlag(CompanyFeatureFlags.PLAN_AGREEMENTS);

  const isRepHomeVisible = ability.can(ActionsEnum.view, SubjectsEnum.RepHome);
  // Don't show the dashboard if repHome is activated, unless the user has access to analyticsDashboard (does not exists yet, so we show the old dashboard).
  const isDashboardVisible = !isRepHomeVisible || ability.can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

  const isDashboardsV2Visible = isDashboardsV2FFEnabled && ability.can(ActionsEnum.view, SubjectsEnum.DashboardsV2);

  const plans = useSelector(selectPlans);

  const dispatch = useThunkDispatch();
  useAsyncEffect(() => dispatch(fetchPlans()), []);

  const auditItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconListSearch />,
      identifier: 'audit',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Audit),
      label: <FormattedMessage defaultMessage="Audit" />,
      route: generatePath(routes.AUDIT),
    }),
    [ability],
  );

  const dashboardItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconChartBar />,
      identifier: 'dashboard',
      isVisible: isDashboardVisible && !isDashboardsV2Visible,
      label: <FormattedMessage defaultMessage="Dashboard" />,
      route: generatePath(routes.DASHBOARD),
    }),
    [isDashboardVisible, isDashboardsV2Visible],
  );

  const dashboardsV2Item: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconChartBar />,
      identifier: 'dashboardsV2',
      isVisible: isDashboardsV2Visible,
      label: <FormattedMessage defaultMessage="Dashboards" />,
      route: generatePath(routes.DASHBOARDS_V2),
    }),
    [isDashboardsV2Visible],
  );

  const dataItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconDatabase />,
      identifier: 'data',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Data),
      label: <FormattedMessage defaultMessage="Data" />,
      route: generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, { modelMachineName: null }),
    }),
    [ability],
  );

  const directoryItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconAddressBook />,
      identifier: 'directory',
      isVisible: ability.can(ActionsEnum.view_directory, SubjectsEnum.Company),
      label: <FormattedMessage defaultMessage="Directory" />,
      route: generatePath(tenantUsersRoutes.DIRECTORY_PAGE),
    }),
    [ability],
  );

  const designerItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconRuler />,
      identifier: 'designer',
      isVisible: ability.can(ActionsEnum.modify, SubjectsEnum.Plan),
      label: <FormattedMessage defaultMessage="Designer" />,
      route: generatePath(routes.DESIGNER),
    }),
    [ability],
  );

  const forecastsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconRadar />,
      identifier: 'forecasts',
      isVisible:
        ability.can(ActionsEnum.view, SubjectsEnum.Statement) &&
        isForecastFFEnabled &&
        !!plans.some((plan) => plan.isForecasted),
      label: <FormattedMessage defaultMessage="Forecasts" />,
      route: generatePath(routes.FORECASTS),
    }),
    [ability, isForecastFFEnabled, plans],
  );

  const overwritesItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconWriting />,
      identifier: 'overwrites',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap),
      label: <FormattedMessage defaultMessage="Overwrites" />,
      route: generatePath(routes.OVERWRITES),
    }),
    [ability],
  );

  const paymentsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconCash />,
      identifier: 'reporting',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Payment),
      label: <FormattedMessage defaultMessage="Payments" />,
      route: generatePath(routes.PAYMENTS),
    }),
    [ability],
  );

  const planAgreementsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconLicense />,
      identifier: 'plan-agreements',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.PlanAgreements) && isPlanAgreementsFFEnabled,
      label: <FormattedMessage defaultMessage="Agreements" />,
      route: generatePath(routes.PLAN_AGREEMENTS_LIST),
    }),
    [ability, isPlanAgreementsFFEnabled],
  );

  const plansItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconFileDescription />,
      identifier: 'plans',
      isVisible: ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {})),
      label: <FormattedMessage defaultMessage="Plans" />,
      route: generatePath(routes.PLANS),
    }),
    [ability],
  );

  const quotasItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconViewfinder />,
      identifier: 'quotas',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Quota),
      label: <FormattedMessage defaultMessage="Quotas" />,
      route: generatePath(routes.QUOTAS),
    }),
    [ability],
  );

  const repHomeItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconHome />,
      identifier: 'home',
      isVisible: isRepHomeVisible,
      label: <FormattedMessage defaultMessage="Home" />,
      route: generatePath(routes.HOME),
    }),
    [isRepHomeVisible],
  );

  const reportsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconTable />,
      identifier: 'customReport',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.CustomReport),
      label: <FormattedMessage defaultMessage="Reports" />,
      route: generatePath(routes.CUSTOM_REPORT_LIST),
    }),
    [ability],
  );

  const statementsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconUserCircle />,
      identifier: 'statements',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Statement),
      label: <FormattedMessage defaultMessage="Statements" />,
      route: generatePath(routes.STATEMENTS),
    }),
    [ability],
  );

  const teamsItem: NavigationItemWithAccessControl = useMemo(
    () => ({
      icon: <IconUsers />,
      identifier: 'teams',
      isVisible: ability.can(ActionsEnum.view, SubjectsEnum.Team),
      label: <FormattedMessage defaultMessage="Teams" />,
      route: generatePath(routes.TEAMS),
    }),
    [ability],
  );

  // Builds the navigation categories
  const rootCategory: NavigationItemWithAccessControl[] = useMemo(
    () => [repHomeItem, statementsItem, forecastsItem, dashboardItem, dashboardsV2Item, reportsItem],
    [repHomeItem, statementsItem, forecastsItem, dashboardItem, dashboardsV2Item, reportsItem],
  );

  const adminCategory: NavigationItemWithAccessControl[] = useMemo(
    () => [designerItem, paymentsItem, overwritesItem, dataItem, auditItem],
    [designerItem, paymentsItem, overwritesItem, dataItem, auditItem],
  );

  const managementCategory: NavigationItemWithAccessControl[] = useMemo(
    () => [directoryItem, teamsItem, plansItem, quotasItem, planAgreementsItem],
    [teamsItem, plansItem, quotasItem, directoryItem, planAgreementsItem],
  );

  return useMemo(() => {
    const filterItems = (items: NavigationItemWithAccessControl[]) =>
      items
        .filter((item) => item.isVisible)
        .map((item) => ({
          ...item,
          isSelected: item.identifier === currentPage,
        }));

    return {
      navigationItems: {
        admin: filterItems(adminCategory),
        management: filterItems(managementCategory),
        root: filterItems(rootCategory),
      },
    };
  }, [adminCategory, managementCategory, rootCategory, currentPage]);
};
