import { CustomObjectDefinitionsApiClient } from '@amalia/data-capture/record-models/state';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { toError } from '@amalia/ext/typescript';
import { type Relationship } from '@amalia/payout-definition/plans/types';
import { RelationshipsApiClient } from '@amalia/payout-definition/state';

import { type ThunkResult } from '../types';

import { CUSTOM_OBJECT_DEFINITIONS_ACTIONS } from './constants';
import {
  type CustomObjectDefinitionsSetCustomObjectDefinitionsAction,
  type CustomObjectDefinitionsSetRelationshipsAction,
  type CustomObjectDefinitionsErrorAction,
  type CustomObjectDefinitionsStartAction,
} from './types';

const customObjectsStart = (): CustomObjectDefinitionsStartAction => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.START,
});

const customObjectsError = (error: Error): CustomObjectDefinitionsErrorAction => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.ERROR,
  error,
});

export const setCustomObjectDefinitions = (
  customObjectDefinitions: CustomObjectDefinition[],
): CustomObjectDefinitionsSetCustomObjectDefinitionsAction => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS,
  payload: { customObjectDefinitions },
});

export const fetchCustomObjectDefinitions =
  (): ThunkResult<
    Promise<CustomObjectDefinitionsErrorAction | CustomObjectDefinitionsSetCustomObjectDefinitionsAction>
  > =>
  async (dispatch) => {
    dispatch(customObjectsStart());
    try {
      const [customObjectDefinitions, virtualCustomObjectDefinitions] = await Promise.all([
        CustomObjectDefinitionsApiClient.list(),
        CustomObjectDefinitionsApiClient.listVirtual(),
      ]);

      return dispatch(setCustomObjectDefinitions([...customObjectDefinitions, ...virtualCustomObjectDefinitions]));
    } catch (error) {
      return dispatch(customObjectsError(toError(error)));
    }
  };

export const setRelationships = (relationships: Relationship[]): CustomObjectDefinitionsSetRelationshipsAction => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS,
  payload: { relationships },
});

export const fetchRelationships =
  (): ThunkResult<Promise<CustomObjectDefinitionsErrorAction | CustomObjectDefinitionsSetRelationshipsAction>> =>
  async (dispatch) => {
    dispatch(customObjectsStart());
    try {
      const relationships = await RelationshipsApiClient.list();

      return dispatch(setRelationships(relationships));
    } catch (error) {
      return dispatch(customObjectsError(toError(error)));
    }
  };
