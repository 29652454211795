import {
  type PaymentContract,
  type CommissionReportFilters,
  type PaymentCategory,
  type PaymentAggregatedListWithFlag,
  type PaymentAmountsByCategory,
} from '@amalia/core/types';
import { toError } from '@amalia/ext/typescript';
import { PaymentApiClient } from '@amalia/payout-calculation/payments/state';
import { StatementPaymentsApiClient } from '@amalia/payout-calculation/statements/state';

import { type ThunkResult } from '../types';

import { PAYMENTS_ACTIONS } from './constants';
import {
  type PaymentsSetCompanyAmountsAction,
  type PaymentsSetStatementAmountsAction,
  type PaymentsSetPaymentCategoryAction,
  type PaymentsSetContextPaymentsAction,
  type PaymentsStartAction,
  type PaymentsErrorAction,
  type PaymentsSetStatementPaymentsAction,
} from './types';

const paymentsStart = (): PaymentsStartAction => ({
  type: PAYMENTS_ACTIONS.START,
});

const paymentsError = (error: Error): PaymentsErrorAction => ({
  type: PAYMENTS_ACTIONS.ERROR,
  error,
});

const setPaymentsForCurrentStatement = (
  payments: Record<PaymentCategory, Partial<PaymentContract>[]>,
): PaymentsSetStatementPaymentsAction => ({
  type: PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS,
  payload: { payments },
});

export const clearPayments = () => ({
  type: PAYMENTS_ACTIONS.CLEAR_PAYMENTS,
});

export const fetchPaymentsForStatement =
  (statementId: string): ThunkResult<Promise<PaymentsErrorAction | PaymentsSetStatementPaymentsAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const payments = await StatementPaymentsApiClient.getStatementPaymentsPerCategory(statementId);
      return dispatch(setPaymentsForCurrentStatement(payments));
    } catch (error) {
      return dispatch(paymentsError(toError(error)));
    }
  };

const setContextualizedPaymentsForCurrentStatement = (
  payments: PaymentContract[],
): PaymentsSetContextPaymentsAction => ({
  type: PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS,
  payload: { payments },
});

export const fetchContextualizedPaymentsForStatement =
  (statementId: string): ThunkResult<Promise<PaymentsErrorAction | PaymentsSetContextPaymentsAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const payments = await StatementPaymentsApiClient.getStatementPayments(statementId);
      return dispatch(setContextualizedPaymentsForCurrentStatement(payments));
    } catch (error) {
      return dispatch(paymentsError(toError(error)));
    }
  };

const setPaymentAmountsForStatement = (
  paymentAmounts: PaymentAmountsByCategory,
): PaymentsSetStatementAmountsAction => ({
  type: PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS,
  payload: { paymentAmounts },
});

export const fetchPaymentAmountsForStatement =
  (statementId: string): ThunkResult<Promise<PaymentsErrorAction | PaymentsSetStatementAmountsAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const amount = await StatementPaymentsApiClient.getStatementAmounts(statementId);
      return dispatch(setPaymentAmountsForStatement(amount));
    } catch (error) {
      return dispatch(paymentsError(toError(error)));
    }
  };

const setPaymentAmountsForCompany = (
  companyAmounts: Partial<PaymentAggregatedListWithFlag>,
): PaymentsSetCompanyAmountsAction => ({
  type: PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS,
  payload: { companyAmounts },
});

export const fetchPaymentAmountsForCompany =
  (
    currentPeriodId?: string,
    filters: CommissionReportFilters = {},
    teamIds?: string[] | null,
    type?: 'commissionReport' | null,
  ): ThunkResult<Promise<PaymentsErrorAction | PaymentsSetCompanyAmountsAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const companyAmounts = await PaymentApiClient.getPaymentsForPeriod(currentPeriodId, filters, teamIds, type);
      return dispatch(setPaymentAmountsForCompany(companyAmounts));
    } catch (error) {
      return dispatch(paymentsError(toError(error)));
    }
  };

export const setPaymentCategory = (category: PaymentCategory): PaymentsSetPaymentCategoryAction => ({
  type: PAYMENTS_ACTIONS.SET_PAYMENT_CATEGORY,
  payload: { category },
});
