import { useQuery } from '@tanstack/react-query';

import { assert } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';

import { PaymentApiClient } from './api-client';
import { paymentsQueryKeys } from './payments.queries.keys';

export const usePaymentsFacets = (periodId?: Period['id']) =>
  useQuery({
    queryKey: paymentsQueryKeys.facets(periodId!),
    queryFn: () => {
      assert(periodId, 'periodId is required');
      return PaymentApiClient.getPaymentsFacets({ periodIds: [periodId] });
    },
    enabled: !!periodId,
  });

export const usePayments = (
  filters: Parameters<(typeof PaymentApiClient)['getPaymentPaginate']>[0],
  { enabled = true } = {},
) =>
  useQuery({
    queryKey: paymentsQueryKeys.list(filters),
    queryFn: () => PaymentApiClient.getPaymentPaginate(filters),
    enabled,
  });
