import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { type SetRequired } from 'type-fest';

import { useAssignmentsWithStatus } from '@amalia/assignments/common/components';
import { Modal, type ModalProps } from '@amalia/design-system/components';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { useArchivePlan } from '@amalia/payout-definition/state';

export type ArchivePlanConfirmModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  readonly plan: SetRequired<Pick<Plan, 'id' | 'name' | 'planAssignements'>, 'planAssignements'>;
  readonly onSuccess?: () => void;
};

export const ArchivePlanConfirmModal = memo(function ArchivePlanConfirmModal({
  plan,
  isOpen,
  onClose,
  onSuccess,
}: ArchivePlanConfirmModalProps) {
  const { mutate: archivePlan, isPending } = useArchivePlan();

  const handleArchivePlan = useCallback(() => archivePlan(plan.id, { onSuccess }), [plan.id, archivePlan, onSuccess]);

  const activeAssignmentsCount = useAssignmentsWithStatus(plan.planAssignements, 'active').length;

  return (
    <Modal
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Archive plan “{planName}”"
              values={{ planName: plan.name }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to archive “<b>{planName}</b>”?{hasAssignments, select, true {{br}{br}This plan has {assignmentsCount, plural, one {# ongoing assignment} other {# ongoing assignments}}. If you archive it, the {assignmentsCount, plural, one {assignment} other {assignments}} will end today.{br}{br}You can unarchive the plan if you change your mind, but you will have to reassign users to the plan.} other {}}"
            values={{
              planName: plan.name,
              hasAssignments: !!activeAssignmentsCount,
              assignmentsCount: activeAssignmentsCount,
              b: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          isLoading={isPending}
          onClick={handleArchivePlan}
        >
          <FormattedMessage defaultMessage="Archive" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
