import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { isConflictError } from '@amalia/core/http/client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import {
  type ImportTokenFromOtherPlanRequest,
  type PatchPlanVisibility,
  type Plan,
} from '@amalia/payout-definition/plans/types';

import { filtersQueryKeys } from '../filters/filters.keys';
import { plansMutationKeys, plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';
import { variablesQueryKeys } from '../variables/variables.keys';

import { PlansApiClient } from './plans.api-client';

export const usePlansList = (
  filters?: Parameters<(typeof PlansApiClient)['list']>[0],
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: plansQueryKeys.list(filters),
    queryFn: () => PlansApiClient.list(filters),
    ...options,
  });

export const usePlanTemplate = (planId?: Plan['id']) =>
  useQuery({
    queryKey: plansQueryKeys.template.ofPlan(planId!),
    queryFn: () => PlansApiClient.getPlanTemplate(planId!),
    enabled: !!planId,
  });

export const usePlan = (planId?: Plan['id']) =>
  useQuery({
    queryKey: plansQueryKeys.details(planId!),
    queryFn: () => PlansApiClient.get(planId!),
    enabled: !!planId,
  });

export const useCreatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.create(),
    mutationFn: PlansApiClient.create,
    onSuccess: async (planCreated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan created successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planCreated.id) }),
      ]);
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be created: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useDuplicatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.duplicate(),
    mutationFn: PlansApiClient.duplicatePlan,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been duplicated successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.all() }),
      ]);
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be duplicated: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useArchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.archive(),
    mutationFn: PlansApiClient.archive,
    onSuccess: async (planUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been archived successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planUpdated.id) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be archived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useUnarchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.archive(),
    mutationFn: PlansApiClient.unarchive,
    onSuccess: async (planUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been unarchived successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planUpdated.id) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be unarchived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useDeletePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.delete(),
    mutationFn: PlansApiClient.delete,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been deleted successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be deleted: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const usePatchPlanVisibility = (planId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patchVisibility(planId),
    mutationFn: (patchPlanVisibility: PatchPlanVisibility) =>
      PlansApiClient.patchPlanVisibility(planId, patchPlanVisibility),
    onSuccess: async (plan: Plan) => {
      snackSuccess(
        plan.isHidden
          ? formatMessage({ defaultMessage: 'Plan has been successfully hidden.' })
          : formatMessage({ defaultMessage: 'Plan has been successfully published.' }),
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planId) }),
      ]);
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useImportTokenFromOtherPlan = (planId: Plan['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: plansMutationKeys.importFromOtherPlan(planId),
    mutationFn: (importTokenFromOtherPlanRequest: ImportTokenFromOtherPlanRequest) =>
      PlansApiClient.importTokenFromOtherPlan({ planId, ...importTokenFromOtherPlanRequest }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: filtersQueryKeys.ofPlan(planId) }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planId) }),
      ]);
    },
  });
};
