export const rulesQueryKeys = {
  all: () => ['rules'] as const,
  list: () => [...rulesQueryKeys.all(), 'list'] as const,
} as const;

export const rulesMutationKeys = {
  all: () => ['rules'] as const,
  create: () => [...rulesMutationKeys.all(), 'create'] as const,
  update: () => [...rulesMutationKeys.all(), 'update'] as const,
  delete: () => [...rulesMutationKeys.all(), 'delete'] as const,
} as const;
