import { assert, toError } from '@amalia/ext/typescript';
import {
  type ObjectsToDisplay,
  type PlanForecast,
  type PlanRuleFieldToDisplay,
  type PlanRuleFilterDisplayConfiguration,
  type PlanRuleKpiSection,
} from '@amalia/payout-definition/plans/types';

import * as PlanForecastRepository from '../../services/plans/planForecasts.repository';
import { addSnackbar } from '../snackbars/actions';
import { type ThunkResult } from '../types';

import { PLAN_FORECAST_ACTIONS } from './constants';
import { selectCurrentPlanForecast } from './selectors';
import {
  type PlanForecastsEditDatasetAction,
  type PlanForecastsEditFieldAction,
  type PlanForecastsEditFieldsToDisplayAction,
  type PlanForecastsEditFieldToDisplayStatusAction,
  type PlanForecastsEditFiltersToDisplayAction,
  type PlanForecastsEditFilterToDisplayStatusAction,
  type PlanForecastsEditKpiAction,
  type PlanForecastsEditObjectsToDisplayAction,
  type PlanForecastsErrorAction,
  type PlanForecastsSetPlanForecastAction,
  type PlanForecastsStartAction,
  type PlansForecastEditKpisToDisplayAction,
  type PlansForecastEditKpiToDisplayStatusAction,
} from './types';

const planForecastStart = (): PlanForecastsStartAction => ({
  type: PLAN_FORECAST_ACTIONS.START,
});

const planForecastError = (error: Error): PlanForecastsErrorAction => ({
  type: PLAN_FORECAST_ACTIONS.ERROR,
  error,
});

const setPlanForecast = (planForecast: PlanForecast | null): PlanForecastsSetPlanForecastAction => ({
  type: PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST,
  payload: { planForecast },
});

const editDataset = (datasetId: string, formula: string): PlanForecastsEditDatasetAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_DATASET,
  payload: { datasetId, formula },
});

const editField = (fieldId: string, formula: string): PlanForecastsEditFieldAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELD,
  payload: { fieldId, formula },
});

const editKpi = (kpiId: string, formula: string): PlanForecastsEditKpiAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPI,
  payload: { kpiId, formula },
});

const editObjectsToDisplay = (
  ruleId: string,
  objectsToDisplay: ObjectsToDisplay,
): PlanForecastsEditObjectsToDisplayAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_OBJECTS_TO_DISPLAY,
  payload: { ruleId, objectsToDisplay },
});

const editKpisToDisplay = (
  ruleId: string,
  kpisToDisplay: PlanRuleKpiSection[],
): PlansForecastEditKpisToDisplayAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPIS_TO_DISPLAY,
  payload: { ruleId, kpisToDisplay },
});

const editKpiToDisplayStatus = (ruleId: string, kpiToDisplayId: string): PlansForecastEditKpiToDisplayStatusAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPI_TO_DISPLAY_STATUS,
  payload: { ruleId, kpiToDisplayId },
});

const editFiltersToDisplay = (
  ruleId: string,
  filtersToDisplay: PlanRuleFilterDisplayConfiguration[],
): PlanForecastsEditFiltersToDisplayAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FILTERS_TO_DISPLAY,
  payload: { ruleId, filtersToDisplay },
});

const editFilterToDisplayStatus = (
  ruleId: string,
  filterToDisplayId: string,
): PlanForecastsEditFilterToDisplayStatusAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FILTER_TO_DISPLAY_STATUS,
  payload: { ruleId, filterToDisplayId },
});

const editFieldsToDisplay = (
  ruleId: string,
  filterId: string,
  fieldsToDisplay: PlanRuleFieldToDisplay[],
): PlanForecastsEditFieldsToDisplayAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELDS_TO_DISPLAY,
  payload: { ruleId, fieldsToDisplay, filterId },
});

const editFieldToDisplayStatus = (
  ruleId: string,
  filterId: string,
  fieldMachinename: string,
): PlanForecastsEditFieldToDisplayStatusAction => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELD_TO_DISPLAY_STATUS,
  payload: { ruleId, fieldMachinename, filterId },
});

export const PlanForecastSyncActions = {
  editDataset,
  editField,
  editKpi,
  editObjectsToDisplay,
  editKpisToDisplay,
  editKpiToDisplayStatus,
  editFiltersToDisplay,
  editFilterToDisplayStatus,
  editFieldsToDisplay,
  editFieldToDisplayStatus,
} as const;

export const fetchPlanForecast =
  (
    planId: string,
    id: string | null,
  ): ThunkResult<Promise<PlanForecastsErrorAction | PlanForecastsSetPlanForecastAction>> =>
  async (dispatch) => {
    dispatch(planForecastStart());
    try {
      if (id) {
        const planForecast = await PlanForecastRepository.get(planId, id);
        return dispatch(setPlanForecast(planForecast));
      }
      return dispatch(setPlanForecast(null));
    } catch (error) {
      return dispatch(planForecastError(toError(error)));
    }
  };

export const editCurrentPlanForecast =
  (
    syncAction: ReturnType<(typeof PlanForecastSyncActions)[keyof typeof PlanForecastSyncActions]>,
  ): ThunkResult<Promise<PlanForecastsErrorAction | PlanForecastsSetPlanForecastAction>> =>
  async (dispatch, getState) => {
    // Synchronously apply diff to current forecast.
    dispatch(syncAction);

    // Get new forecast and try to update it.
    const newPlanForecast = selectCurrentPlanForecast(getState());

    try {
      dispatch(planForecastStart());

      assert(newPlanForecast, 'PlanForecast is not set');

      const updatedForecast = await PlanForecastRepository.update(
        newPlanForecast.planId,
        newPlanForecast.id,
        newPlanForecast,
      );
      dispatch(addSnackbar({ message: 'Forecast updated!', options: { variant: 'success' } }));
      return dispatch(setPlanForecast(updatedForecast));
    } catch (error) {
      dispatch(addSnackbar({ message: 'Failed to update Forecast!', options: { variant: 'error' } }));
      return dispatch(planForecastError(toError(error)));
    }
  };
