import { last } from 'lodash';

import {
  CustomReportSourceIdentifier,
  type CustomReport,
  type CustomReportColumn,
  type CustomReportConfigurationField,
  type CustomReportDataSourceManifest,
  type CustomReportManifestsMap,
} from '../types/customReport';

export const getCustomReportFieldDefinition = (
  customReport: CustomReport,
  manifestsMap: CustomReportManifestsMap,
  field: { identifier: string; joins?: string[] },
): CustomReportDataSourceManifest['fields'][0] | null => {
  const source = (field.joins?.length ? last(field.joins) : customReport.source) as string;
  const manifest = manifestsMap[source];

  // Manifest may not be loaded yet.
  if (!manifest) {
    return null;
  }

  return manifest.fields.find((fd) => fd.identifier === field.identifier) || null;
};

export const makeUniqueCustomReportFieldIdentifier = (
  source: CustomReportSourceIdentifier,
  fieldConfiguration: Pick<CustomReportConfigurationField, 'identifier' | 'joins'>,
) => {
  const tableAlias = fieldConfiguration.joins?.length ? last(fieldConfiguration.joins) : source;

  return `${tableAlias}__${fieldConfiguration.identifier}`;
};

export const getCustomReportColumnsDefinitions = (customReport: CustomReport, manifestsMap: CustomReportManifestsMap) =>
  customReport.configuration.fields
    .map((fieldConfiguration) => {
      const fieldDefinition = getCustomReportFieldDefinition(customReport, manifestsMap, fieldConfiguration);

      // Some fields may have disappeared from the manifest. Remove them to avoid crashes.
      if (!fieldDefinition) {
        return null;
      }

      return {
        fieldDefinition,
        field: fieldConfiguration,
        identifier: makeUniqueCustomReportFieldIdentifier(customReport.source, fieldConfiguration),
      };
    })
    .filter(Boolean);

export function buildCustomReportColumns(
  customReport: CustomReport,
  manifestsMap: CustomReportManifestsMap,
): CustomReportColumn[] {
  return getCustomReportColumnsDefinitions(customReport, manifestsMap).map(
    ({ fieldDefinition, field, identifier }) => ({
      identifier,
      label: field.alias || fieldDefinition.label,
      format: fieldDefinition.format,
    }),
  );
}

// PSQL max identifier length is 63 characters.
// We need to truncate the identifier to 63 characters to avoid errors.
export const formatMaxLengthIdentifierPsql = (identifier: string): string => identifier.substring(0, 63);

export const stringifyCustomReportFieldPosition = ({ identifier, joins }: CustomReportConfigurationField): string =>
  JSON.stringify({ identifier, joins });

export const parseCustomReportFieldPosition = (value: string): CustomReportConfigurationField => ({
  joins: undefined,
  ...(JSON.parse(value) as CustomReportConfigurationField),
});

export const getCustomReportJoinForUser = (source: CustomReportSourceIdentifier) => {
  switch (source) {
    case CustomReportSourceIdentifier.RULE_METRIC:
      return 'ruleMetricUser';
    case CustomReportSourceIdentifier.STATEMENT:
      return 'statementUser';
    default:
      throw new Error('This custom report source is not supported');
  }
};
