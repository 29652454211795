import { createSelector } from 'reselect';

import { sortUsers } from '../../services/users/users.service';
import { type RootState } from '../types';

export const selectUsersMap = (state: RootState) => state.users.map;

export const selectUsersList = createSelector([selectUsersMap], (map) => sortUsers(Object.values(map)));

export const selectIsUsersLoading = (state: RootState) => !!state.users.isLoading;
