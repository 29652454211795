import { useMemo } from 'react';

import { type Plan } from '@amalia/payout-definition/plans/types';

export const useUsedInPlans = (tokenId: string | null, plansList: Plan[], excludeCurrentPlanId?: string) =>
  useMemo(
    () =>
      tokenId
        ? plansList
            .filter((p) => (p.indexedPlanTemplate?.dependencyIds || []).includes(tokenId))
            .map((p) => ({ name: p.name, id: p.id, isHidden: p.isHidden, archived: p.archived }))
            .toSorted((a, b) => a.name.localeCompare(b.name))
            .filter((p) => (excludeCurrentPlanId ? p.id !== excludeCurrentPlanId : true))
        : [],
    [tokenId, plansList, excludeCurrentPlanId],
  );
