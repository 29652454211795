import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type ReduxAction } from '../types';

import { FILTERS_ACTIONS } from './constants';
import { type FiltersReducer } from './types';

export const filtersReducer = combineReducers<FiltersReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case FILTERS_ACTIONS.START:
        return state + 1;
      case FILTERS_ACTIONS.ERROR:
      case FILTERS_ACTIONS.SET_FILTERS:
        return state - 1;
      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case FILTERS_ACTIONS.SET_FILTERS:
        return keyBy(action.payload.filters, 'id');
      default:
        return state;
    }
  },
});
