import { orderBy } from 'lodash';
import { isSymbolNode, type ConstantNode, type SymbolNode } from 'mathjs';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { AmaliaFunction, type MathNode, SanitizeFormula } from '@amalia/amalia-lang/formula/evaluate/shared';
import {
  AmaliaAccessorKeywords,
  type Variable,
  type VariableDefinition,
  VariableType,
} from '@amalia/amalia-lang/tokens/types';
import {
  type ComputedStatement,
  type DatasetRow,
  getFieldAsOption,
  type Statement,
  type StatementDataset,
  type ComputedStatementDefinitions,
  FormulaNodeType,
} from '@amalia/core/types';
import { type BaseCustomObjectDefinition, type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { assert } from '@amalia/ext/typescript';
import { log } from '@amalia/kernel/logger/client';
import {
  type ComputedFunctionArgs_SUM,
  type ComputedFunctionResult,
  type ComputedItem,
  ComputedItemTypes,
  type ComputedRule,
  type ComputedVariable,
  type Dataset,
  DatasetType,
  type FilterDataset,
  type RelationDataset,
} from '@amalia/payout-calculation/types';
import { type UserContract } from '@amalia/tenants/users/types';

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

export const getDatasetByParentIdAndFilterMachineName = (
  computedStatement: ComputedStatement,
  filterMachineName: string,
  computedObjectId?: string,
): FilterDataset | undefined =>
  (computedStatement.datasets as FilterDataset[]).find(
    (dataset) =>
      (computedObjectId ? dataset.parentIds.includes(computedObjectId) : true) &&
      dataset.filterMachineName === filterMachineName,
  );

export const parseRowMarginalIndex = (
  nodeToPush: any,
  computedItemVariable: VariableDefinition,
  computedRule: ComputedRule,
  statement: Statement,
  statementDatasets: Record<string, StatementDataset>,
  datasetRow?: DatasetRow,
) => {
  if (!computedItemVariable.formula) {
    return nodeToPush;
  }

  const parsedResults = commonMathJs.parse(SanitizeFormula.amaliaFormulaToMathJs(computedItemVariable.formula)!) as any;

  const rowMarginalArguments = ['add', 'multiply'].includes(parsedResults.fn)
    ? parsedResults.args[0]?.args
    : parsedResults.args;

  // If we have a SORT function wrapping the filter, then the filter name is the first parameter of the SORT.
  const filterOrRelationMachineName =
    rowMarginalArguments[2]?.fn?.name === 'SORT'
      ? rowMarginalArguments[2]?.args[0].index?.dimensions?.[0]?.value
      : rowMarginalArguments[2]?.index?.dimensions?.[0]?.value;

  let filterComputationObject: FilterDataset | RelationDataset | undefined;

  filterComputationObject = statement.results.datasets.find(
    (dataset) => dataset.type === DatasetType.filter && dataset.filterMachineName === filterOrRelationMachineName,
  ) as FilterDataset | undefined;

  if (!filterComputationObject) {
    return {};
  }

  let filterComputationObjectRows: DatasetRow[];

  if (filterComputationObject.type === DatasetType.filter) {
    // Something somewhere overwrites the dataset id to replace it with the filterId,
    // so we're recomputing the id based on definition, which is something that should never
    // be done. Tracing v2 should solve this, hopefully.
    const datasetId = `f:${filterComputationObject.customObjectDefinition.machineName}:${filterComputationObject.filterMachineName}`;
    if (!(datasetId in statementDatasets)) {
      log.warn('Cannot display dataset', filterComputationObject);
      return {};
    }

    filterComputationObjectRows = statementDatasets[datasetId].rows.slice();
  } else {
    // @ts-expect-error -- It still works for relation datasets, because they haven't been extracted.
    filterComputationObjectRows = ((filterComputationObject.rows as DatasetRow[] | undefined) || []).slice();
  }

  // Additional fields that will be displayed in the rowMarginal filter
  const fieldsToAdd = [];

  // The fieldToSum is the fourth parameter.
  const fieldToSumMachineName = rowMarginalArguments[3]?.value;
  fieldsToAdd.push({ name: fieldToSumMachineName, label: fieldToSumMachineName });

  // The uniqueIdField is the fifth parameter
  const uniquerIdField = rowMarginalArguments[4]?.value;
  fieldsToAdd.push({ name: uniquerIdField, label: uniquerIdField });

  // If using SORT, grab its second parameter.
  const unparsedSortColumns = rowMarginalArguments[2]?.fn?.name === 'SORT' ? rowMarginalArguments[2].args[1] : null;

  const sortColumns = [];
  if (unparsedSortColumns) {
    // If SORT is used, we need to show the sort columns in the fields

    if (unparsedSortColumns.items) {
      sortColumns.push(...unparsedSortColumns.items.map((item: any) => ({ name: item?.value, label: item?.value })));
    } else {
      sortColumns.push({ name: unparsedSortColumns.value, label: unparsedSortColumns.value });
    }

    filterComputationObjectRows = orderBy(filterComputationObjectRows, [
      ...sortColumns.map((s) => `content.${s.name}`),
      'id',
    ]);
    fieldsToAdd.push(...sortColumns);
  }

  const computationItemMachineName = computedItemVariable.machineName;
  if (datasetRow?.id) {
    // Push machineName of item that has the rowMarginal to see it in filter
    fieldsToAdd.push({
      name: computationItemMachineName,
      label: computedItemVariable.name || computationItemMachineName,
    });

    if (filterComputationObjectRows) {
      // Modify filter rows to add machineName of variable that includes rowMaginalIndex
      filterComputationObjectRows = filterComputationObjectRows.map((row: DatasetRow) => ({
        ...row,
        content: {
          ...row.content,
          ...(row.externalId === datasetRow.externalId
            ? {
                [computationItemMachineName]: datasetRow.content[computationItemMachineName],
              }
            : {
                // Set to null to not print anything
                [computationItemMachineName]: null,
              }),
        },
      }));
    }
  }

  const startAmount = rowMarginalArguments[5]?.toString({
    handler: FormulaService.toArrayHandler,
    computedRule,
    statement,
    statementDatasets,
    datasetRow,
  })?.[0];

  const computedItemInStatement = rowMarginalArguments[1]?.toString({
    handler: FormulaService.toArrayHandler,
    computedRule,
    statement,
    statementDatasets,
    datasetRow,
  });

  let tracingTable;

  if (computedItemInStatement?.[0].type === 'custom_object') {
    // If it's an object variable, we have to fetch the table in the row.
    const [itemDefinition] = computedItemInStatement;
    const key = itemDefinition?.value?.machineName;
    tracingTable = key && datasetRow?.content[key];
  } else {
    // If not, it's a global variable so we just have to read its value.
    tracingTable = computedItemInStatement?.[0]?.value?.value;
  }

  return {
    // Filter to show
    filter: {
      ...filterComputationObject,
      rows: filterComputationObjectRows,
    },
    // Additional fields to show on rowMarginal filter
    fieldsToAdd,
    // Field machine name that contains the field to sum
    fieldToSum: fieldToSumMachineName,
    // Sort columns
    sortColumns,
    // Total
    total: computationItemMachineName ? datasetRow?.content[computationItemMachineName] : null,
    // External id of the row that is traced in the parent filter
    // This row will have to be highlighted in the child filter if found
    parentExternalId: datasetRow?.externalId,
    startAmount,
    tracingTable,
  };
};

export class FormulaService {
  public static getFormulaNodes(
    formula: string,
    ruleResult: ComputedRule,
    statement: Statement,
    statementDatasets: Record<string, StatementDataset>,
    datasetRow?: DatasetRow,
    dataSet?: Dataset,
  ): any[] {
    const formulaNode = commonMathJs.parse(formula);
    return formulaNode.toString({
      handler: FormulaService.toArrayHandler,
      ruleResult,
      statement,
      datasetRow,
      statementDatasets,
      dataSet,
    }) as any;
  }

  /**
   * Retrieve all custom object fields included in formula.
   */
  public static getFormulaObjectsFields(
    formula: string,
    definition: BaseCustomObjectDefinition,
    computedStatement: ComputedStatement,
    fields?: { name: string; label: string }[],
    recursiveIndex = 0,
  ): { name: string; label: string }[] {
    const sanitizedFormula = SanitizeFormula.amaliaFormulaToMathJs(formula)!;
    const formulaNode = commonMathJs.parse(sanitizedFormula);
    const formulaFields = fields || [];

    formulaNode.toString({
      handler: FormulaService.formulaObjectFieldsHandler,
      computedStatement,
      definition,
      fields: formulaFields,
      recursiveIndex,
      formula,
    });

    return formulaFields;
  }

  public static readonly formulaObjectFieldsHandler = (
    node: MathNode,
    options: {
      computedStatement: ComputedStatement;
      definition: CustomObjectDefinition;
      fields: { name: string; label: string }[];
      recursiveIndex: number;
      formula: string;
    },
  ): { name: string; label: string }[] => {
    const { computedStatement, definition, fields, formula } = options;
    const recursiveIndex = options.recursiveIndex + 1;

    if (recursiveIndex > 10) {
      log.error(`Infinite recursive loop on retrieving fields for formula ${options.formula}`);
      return fields;
    }

    if (commonMathJs.isAccessorNode(node)) {
      assert(commonMathJs.isSymbolNode(node.object), 'Node object should be a symbol node');

      const { value } = node.index.dimensions[0] as ConstantNode<string>;
      if (node.object.name === definition.machineName && !fields.find((field) => field.name === value)) {
        const field = getFieldAsOption(definition, value);
        if (field) {
          fields.push(field);
        } else {
          // Field is not included in the object definition => it is an object variable.
          // We need to retrieve variable from computed statement to get its label.
          const fieldDefinitionMatchingVariable = computedStatement.definitions.variables[value];

          const label = (fieldDefinitionMatchingVariable as Variable | undefined)?.name || value;
          fields.push({ name: value, label });
        }
      }

      const variableDefinition =
        value in computedStatement.definitions.variables ? computedStatement.definitions.variables[value] : undefined;

      if (variableDefinition?.formula) {
        FormulaService.getFormulaObjectsFields(
          variableDefinition.formula,
          definition,
          computedStatement,
          fields,
          recursiveIndex + 1,
        );
      }
    } else if (commonMathJs.isFunctionNode(node) && node.fn.name === FunctionsEnum['SUM']) {
      const nodeSumFilter = node.args[0]?.toString();
      const nodeSumFormula = node.args[1]?.toString();

      const sumComputedObject: ComputedFunctionResult | undefined = computedStatement.computedObjects
        .map((co) => co.evaluations || [])
        .flat()
        .find(
          (ev) =>
            ev.function === 'SUM' &&
            (ev.args as ComputedFunctionArgs_SUM | undefined)?.array === nodeSumFilter &&
            (ev.args as ComputedFunctionArgs_SUM | undefined)?.formula === nodeSumFormula,
        );

      const sumFormula = (sumComputedObject?.args as ComputedFunctionArgs_SUM | undefined)?.formula;
      if (sumFormula) {
        FormulaService.getFormulaObjectsFields(sumFormula, definition, computedStatement, fields, recursiveIndex + 1);
      }

      // @ts-expect-error -- well
      const sumFilterDefinition = computedStatement.definitions.filters[node.args[0]?.index?.dimensions[0]] as
        | ComputedStatementDefinitions['filters'][string]
        | undefined;

      const filterFormula = sumFilterDefinition?.condition ?? '';

      if (filterFormula) {
        FormulaService.getFormulaObjectsFields(
          filterFormula,
          definition,
          computedStatement,
          fields,
          recursiveIndex + 1,
        );
      }
    } else if (commonMathJs.isParenthesisNode(node)) {
      FormulaService.formulaObjectFieldsHandler(node.content, {
        computedStatement,
        definition,
        fields,
        recursiveIndex: recursiveIndex + 1,
        formula,
      });
    } else if ((commonMathJs.isOperatorNode(node) || commonMathJs.isFunctionNode(node)) && node.args.length > 0) {
      for (const nodeArg of node.args) {
        FormulaService.formulaObjectFieldsHandler(nodeArg, {
          computedStatement,
          definition,
          fields,
          recursiveIndex: recursiveIndex + 1,
          formula,
        });
      }
    }

    return fields;
  };

  public static readonly toArrayHandler = (
    node: MathNode | null,
    options: {
      ruleResult: ComputedRule;
      statement: Statement;
      statementDatasets: Record<string, StatementDataset>;
      datasetRow?: DatasetRow;
      dataSet?: Dataset;
    },
  ): any[] => {
    const nodeArray: any[] = [];

    const { ruleResult, statement, datasetRow, dataSet, statementDatasets } = options;

    if (node) {
      if (commonMathJs.isOperatorNode(node)) {
        if (node.args.length === 1) {
          // If this is operator is unary (not, -1, etc...)
          nodeArray.push({ value: node.op, type: FormulaNodeType.operator });
          nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
        } else {
          // else (+-/*) parse the preceding nodes
          nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
          // Push the operator
          nodeArray.push({ value: node.op, type: FormulaNodeType.operator });
          // and also the following nodes
          nodeArray.push(...FormulaService.toArrayHandler(node.args[1], options));
        }
      } else if (commonMathJs.isArrayNode(node)) {
        // Just push the array as a string
        nodeArray.push({
          value: node.toString(),
          type: FormulaNodeType.array,
          values: node.items.map((item: any) => FormulaService.toArrayHandler(item, options)),
        });
      } else if (commonMathJs.isConstantNode(node)) {
        // Just push the constant
        nodeArray.push({ value: node.value, type: FormulaNodeType.constant });
      } else if (commonMathJs.isSymbolNode(node)) {
        // Just push the symbol
        nodeArray.push({ value: node.name, type: FormulaNodeType.constant });
      } else if (commonMathJs.isAccessorNode(node)) {
        const itemMachineName = (node.index.dimensions[0] as ConstantNode<string>).value;
        switch ((node.object as SymbolNode).name) {
          case AmaliaAccessorKeywords.statement: {
            const computedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableType.statement &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // For statement variables, we have to get its value from the computation item from its machineName
            nodeArray.push({
              value: {
                ...computedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: computedObject?.value,
                type: FormulaNodeType.statement,
              },
              type: FormulaNodeType.statement,
            });
            break;
          }
          case AmaliaAccessorKeywords.user: {
            // For user variables, we have first to fetch the corresponding computation item from its machine name
            const userComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableType.user &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // Also, we fetch the variable from the user statement
            const userFromComputedStatement = statement.results.planAssignment.user;
            // If found in the user statement, format it as a computation item
            const userVariableValue = Object.keys(userFromComputedStatement).includes(itemMachineName)
              ? {
                  total: userFromComputedStatement[itemMachineName as keyof UserContract],
                  formula: userFromComputedStatement[itemMachineName as keyof UserContract],
                  type: FormulaNodeType.user,
                  name: itemMachineName,
                  label: itemMachineName,
                  machineName: itemMachineName,
                }
              : null;

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: userComputedObject
                ? {
                    ...userComputedObject,
                    ...statement.results.definitions.variables[itemMachineName],
                    total: userComputedObject.value,
                  }
                : userVariableValue,
              type: FormulaNodeType.user,
            };

            if (nodeToPush.value && !nodeToPush.value.formula) {
              nodeToPush.value.formula = nodeToPush.value.total?.toString() ?? null;
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case AmaliaAccessorKeywords.team: {
            const teamComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableType.team &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: {
                ...teamComputedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: teamComputedObject?.value,
              },
              type: FormulaNodeType.team,
            };

            if (!nodeToPush.value.formula && nodeToPush.value.total) {
              nodeToPush.value.formula = nodeToPush.value.total.toString();
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case AmaliaAccessorKeywords.plan: {
            const planComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableType.plan &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: {
                ...planComputedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: planComputedObject?.value,
              },
              type: FormulaNodeType.plan,
            };

            if (!nodeToPush.value.formula && nodeToPush.value.total) {
              nodeToPush.value.formula = nodeToPush.value.total.toString();
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case AmaliaAccessorKeywords.filter: {
            // Get filter by rule
            const filterComputationObject = getDatasetByParentIdAndFilterMachineName(
              statement.results,
              itemMachineName,
              datasetRow?.id,
            );

            const datasetDefinition =
              itemMachineName in statement.results.definitions.filters
                ? statement.results.definitions.filters[itemMachineName]
                : undefined;

            if (filterComputationObject) {
              // If we retrieve a filter, then add it. Otherwise, print it as a constant
              const filterFormula = datasetDefinition?.condition ?? '';

              nodeArray.push({
                value: {
                  ...filterComputationObject,
                  ...datasetDefinition,
                  formula: filterFormula,
                  type: FormulaNodeType.filter_dataset,
                },
                type: FormulaNodeType.filter_dataset,
              });
            } else {
              nodeArray.push({
                value: ((node as any)?.formula || node).toString(),
                type: FormulaNodeType.constant,
              });
            }
            break;
          }
          default: {
            const objectName = isSymbolNode(node.object) ? node.object.name : undefined;
            const customObjectDefinition =
              objectName && objectName in statement.results.definitions.customObjects
                ? statement.results.definitions.customObjects[objectName]
                : undefined;
            // If current accessor node corresponds to a custom object definition
            if (customObjectDefinition) {
              const propertyMachineName = (node.index.dimensions[0] as ConstantNode<string>).value;

              const computedObjects = dataSet ? dataSet.computedItems : statement.results.computedObjects;

              // For custom object variables, get the computation item
              const computationItem = computedObjects.find(
                (co: ComputedItem) =>
                  co.type === ComputedItemTypes.VARIABLE &&
                  (co as ComputedVariable).variableType === VariableType.object &&
                  (co as ComputedVariable).variableMachineName === propertyMachineName,
              );

              const computationItemDefinition = computationItem
                ? statement.results.definitions.variables[(computationItem as ComputedVariable).variableMachineName]
                : null;

              const label =
                computationItemDefinition?.name ||
                customObjectDefinition.properties[propertyMachineName]?.name ||
                propertyMachineName;

              const nodeToPush = {
                value: {
                  label,
                  formula: (computationItemDefinition?.formula as any) || null,
                  name: propertyMachineName,
                  machineName: propertyMachineName,
                  customObjectMachineName: isSymbolNode(node.object) ? node.object.name : undefined,
                  format: computationItemDefinition?.format,
                  type: computationItem ? FormulaNodeType.variable : FormulaNodeType.custom_object,
                  // May be populated after with a non typed variable
                  subFilter: null,
                },
                type: computationItem ? FormulaNodeType.variable : FormulaNodeType.custom_object,
              };

              if (computationItemDefinition?.formula?.includes('rowMarginalIndex')) {
                nodeToPush.value.subFilter = parseRowMarginalIndex(
                  nodeToPush,
                  computationItemDefinition,
                  ruleResult,
                  statement,
                  statementDatasets,
                  datasetRow,
                );
                nodeToPush.type = FormulaNodeType.rowMarginal;
                nodeToPush.value.type = FormulaNodeType.rowMarginal;
              }

              nodeArray.push(nodeToPush);
            } else {
              // If the value is linked to another type of data, just push its name without trying to retrieve a value
              nodeArray.push({
                value: ((node as any)?.formula || node).toString(),
                type: FormulaNodeType.constant,
              });
            }
          }
        }
      } else if (commonMathJs.isFunctionNode(node)) {
        // Function node: push the function + its parameters formatted with operators

        // Overloads
        switch (node.fn.name) {
          case 'equal':
            // Only add first parameter
            if (node.args[0]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
            }
            break;
          case 'compareNatural':
            // Don't print function name and replace comma with =
            if (node.args[0]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
            }
            nodeArray.push({ value: '=', type: FormulaNodeType.operator });
            if (node.args[1]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args[1], options));
            }
            break;
          default: {
            nodeArray.push({
              // @ts-expect-error -- fuck this
              value: { function: (node.fn.name as typeof FunctionsEnum) || node.fn.name },
              type: FormulaNodeType.function,
              // @ts-expect-error -- fuck that
              name: (node.fn.name as typeof FunctionsEnum) || node.fn.name,
            });
            nodeArray.push({ value: '(', type: FormulaNodeType.operator });
            node.args.forEach((arg: any, index: number) => {
              if (index > 0) {
                nodeArray.push({ value: ',', type: FormulaNodeType.operator });
              }

              // Particular case for second argument of SUM: all customObjects and variables
              // should be converted to customObjects with no value
              if (node.fn.name === FunctionsEnum['SUM'] && index === 1) {
                nodeArray.push(
                  ...FormulaService.toArrayHandler(node.args[1], options).map((childNode: any) => {
                    if ([FormulaNodeType.custom_object, FormulaNodeType.variable].includes(childNode.type)) {
                      return {
                        ...childNode,
                        value: {
                          ...childNode.value,
                          // Remove value
                          total: null,
                          // Override type to custom object
                          type: FormulaNodeType.custom_object,
                        },
                        type: FormulaNodeType.custom_object,
                      };
                    }
                    return childNode;
                  }),
                );
              } else {
                nodeArray.push(...FormulaService.toArrayHandler(arg, options));
              }
            });
            nodeArray.push({ value: ')', type: FormulaNodeType.operator });
          }
        }
      } else if (commonMathJs.isParenthesisNode(node)) {
        // For parenthesis node alone, just puth the child value surrounded by parenthesis nodes
        nodeArray.push({ value: '(', type: FormulaNodeType.operator });
        // Push the child nodes
        nodeArray.push(...FormulaService.toArrayHandler(node.content, options));
        nodeArray.push({ value: ')', type: FormulaNodeType.operator });
      } else {
        // If this is another type of node, just push it like this, don't parse it
        // @ts-expect-error -- fuck this as well
        nodeArray.push(node.value);
      }
    }

    return nodeArray;
  };
}
