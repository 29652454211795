import { type Statement } from '@amalia/core/types';

import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const forecastedStatementsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement);
  },

  EMPLOYEE({ user }, { can }) {
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.calculate, SubjectsEnum.Forecasted_Statement, ({ userId }: Statement) => userId === user.id);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
