import { type CommissionReportFilters } from '@amalia/core/types';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type TeamContract } from '@amalia/tenants/teams/types';

export const paymentsQueryKeys = {
  all: () => ['payments'] as const,
  facets: (periodId: Period['id']) => [...paymentsQueryKeys.all(), 'facets', periodId] as const,
  list: (filters: {
    filters?: CommissionReportFilters;
    teamIds?: TeamContract['id'][] | null;
    page?: number;
    limit?: number;
    searchValue?: string;
    sortColumn?: string;
    sortOrder?: 'asc' | 'desc';
  }) => [...paymentsQueryKeys.all(), 'list', filters] as const,
} as const;
