import { useIsFetching, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamsApiClient } from '@amalia/tenants/teams/api-client';
import { type TeamContract, type TeamMap } from '@amalia/tenants/teams/types';

import { teamsQueryKeys } from './queries.keys';

export const useTeams = (
  { sortByName, showArchivedTeams = true }: { sortByName?: boolean; showArchivedTeams?: boolean } = {},
  options?: { enabled?: boolean },
) => {
  const { snackError } = useSnackbars();

  const {
    data: teams,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: teamsQueryKeys.list(),
    queryFn: () => TeamsApiClient.getTeams(),
    ...options,
  });

  const filteredTeams = useMemo(
    () => (showArchivedTeams ? teams : teams?.filter((team) => !team.archived)),
    [showArchivedTeams, teams],
  );

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  const { teamsList, teamsMap }: { teamsList: TeamContract[]; teamsMap: TeamMap } = useMemo(
    () => ({
      teamsList: sortByName
        ? (filteredTeams || []).toSorted((a, b) => a.name.localeCompare(b.name))
        : filteredTeams || [],
      teamsMap: keyBy(filteredTeams || [], 'id'),
    }),
    [filteredTeams, sortByName],
  );

  return {
    teamsList,
    teamsMap,
    isError,
    ...rest,
  };
};

export const useIsTeamsLoading = () => useIsFetching({ queryKey: teamsQueryKeys.all() }) > 0;
