import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil, uniq } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { authzContextMutationKeys, authzContextQueryKeys } from '@amalia/kernel/auth/state';
import { NotificationsApiClient, UserApiClient } from '@amalia/tenants/users/api-client';
import { type KeysOfUserWithStringValues, type UsersMap } from '@amalia/tenants/users/types';

import { notificationsQueryKeys, userInformationQueryKeys } from './queries.keys';

export const useUsersInformations = (
  usersToFetch: [property: KeysOfUserWithStringValues, value: string][],
  enabled: boolean = true,
) =>
  useQueries({
    queries: usersToFetch.map(([property, value]) => ({
      queryKey: userInformationQueryKeys.byProperty(property, value),
      queryFn: () => UserApiClient.bufferedFetchUserBy(property, value),
      enabled,
    })),
  });

export const useUsersById = (userIds: string[], enabled: boolean = true) =>
  useUsersInformations(
    userIds.map((id) => ['id', id]),
    enabled,
  );

export const useUsersMap = (userIds: string[], enabled: boolean = true) => {
  const queries = useUsersById(uniq(userIds), enabled);

  return useMemo(
    () =>
      queries.reduce<UsersMap>((acc, query) => {
        if (query.data) {
          acc[query.data.id] = query.data;
        }
        return acc;
      }, {}),
    [queries],
  );
};

export const useUserInformations = (property: KeysOfUserWithStringValues, value: string) =>
  useQuery({
    queryKey: userInformationQueryKeys.byProperty(property, value),
    queryFn: () => UserApiClient.bufferedFetchUserBy(property, value),
    refetchOnWindowFocus: false,
    enabled: !!value,
  });

/**
 * @deprecated -- Prefer "useAuthorizedProfiles()" instead
 */
export const useDeprecatedCompanyUsers = () =>
  useQuery({
    queryKey: userInformationQueryKeys.list(),
    queryFn: () => UserApiClient.fetchAllUsers(),
  });

export const useUpdateUserSettings = () => {
  const { snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: authzContextMutationKeys.updateSettings(),
    mutationFn: UserApiClient.updateSettings,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: authzContextQueryKeys.all() });
      snackSuccess(formatMessage({ defaultMessage: 'Settings successfully updated!' }));
    },
  });
};

export const useInfiniteNotifications = () => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: notificationsQueryKeys.pages(),
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) => NotificationsApiClient.fetchNotifications(pageParam),
    getNextPageParam: (lastPage, allPages) =>
      !isNil(lastPage.paginatedNotifications.pageCount) && allPages.length < lastPage.paginatedNotifications.pageCount
        ? allPages.length + 1
        : undefined,
    initialPageParam: 1,
  });

  const notifications = useMemo(
    () => data?.pages.flatMap((page) => page.paginatedNotifications.items) || [],
    [data?.pages],
  );

  return {
    data: notifications,
    ...rest,
  };
};

export const useUnreadNotificationsCount = () =>
  useQuery({
    queryKey: notificationsQueryKeys.unreadCount(),
    queryFn: () => NotificationsApiClient.fetchUnreadCount(),
    refetchInterval: 15_000,
  });

export const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: Parameters<(typeof NotificationsApiClient)['markAsRead']>) => {
      await NotificationsApiClient.markAsRead(...args);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: notificationsQueryKeys.all() });
    },
  });
};
