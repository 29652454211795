import { type RootState } from '../types';

export const selectIsAppLoading = (state: RootState) =>
  !!(
    state.currencies.isLoading ||
    state.customObjectDefinitions.isLoading ||
    state.filters.isLoading ||
    state.planForecasts.isLoading ||
    state.overwrites.isLoading ||
    state.payments.isLoading ||
    state.plans.isLoading ||
    state.statements.isLoading ||
    state.users.isLoading
  );
