import {
  select,
  type NumberValue,
  extent,
  scaleLinear,
  axisBottom,
  max,
  axisLeft,
  axisRight,
  line,
  type Line,
} from 'd3';
import { useRef, useEffect, memo } from 'react';

import { useChartStyles, defineCommonSVGUtils, buildChartClasses, getAxisClasses } from './chart.styles';
import {
  type CardinalChartWithNumberOnlyProps,
  type CardinalChartRowWithNumberOnly,
  type CardinalChartRow,
  type CardinalGraphAxis,
} from './chart.types';

interface LineProps extends CardinalChartWithNumberOnlyProps {}

export const LineChart = memo(function LineChart({
  data,
  height,
  width,
  margin,
  styles,
  options,
  modifiers,
}: LineProps) {
  const classes = useChartStyles();
  const d3Container = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (d3Container.current) {
      const svg = select(d3Container.current);

      defineCommonSVGUtils(svg);

      // X Axis
      // X is a linear scale
      const x = scaleLinear()
        // Build from the extent of the X values
        .domain(
          extent(
            data.map((datum: CardinalChartRowWithNumberOnly) => ({ x: datum.x, y: datum.value })),
            (d: { x: number; y: number }) => d.x,
          ) as NumberValue[],
        )
        // Which corresponds in pixels from the left (margin) to the right (width - margin)
        .range([margin, width - margin]);

      // Building X ticks
      const xTicks = axisBottom(x).ticks(width / 80);

      if (options?.formatXticks) {
        xTicks.tickFormat(options.formatXticks);
      }

      // Building the X axis
      const xAxis = (g: CardinalGraphAxis) =>
        g
          // Position it a bit offset from the Y axis (to let it a bit of space) and to the bottom (height - margin)
          .attr('transform', `translate(${(options?.isRightVerticalAxis ? -1 : 1) * margin * 0.5}, ${height - margin})`)
          // Add common class
          .attr('class', getAxisClasses(classes, 'dimensional', styles))
          // Add readable ticks
          .call(xTicks);

      // Y Axis
      // Y is a linear scale
      const y = scaleLinear()
        // between 0 and the max of data's values
        .domain([0, max(data, (d: CardinalChartRow) => d.value) || 0])
        .nice()
        // which corresponds in pixels from the bottom (height - margin) to the top (margin)
        .range([height - margin, margin]);

      // Building Y Ticks
      const yAxisFn = options?.isRightVerticalAxis ? axisRight : axisLeft;
      const yTicks = yAxisFn(y).ticks(height / 80);

      if (options?.formatYticks) {
        yTicks.tickFormat(options.formatYticks);
      }

      // Building the Y Axis
      const yAxis = (g: CardinalGraphAxis) =>
        g
          // Position it to the left (margin * 1.5) or the right (width - margin * 1.5) accordingly, and the top
          .attr('transform', `translate(${options?.isRightVerticalAxis ? width - margin * 1.5 : margin * 1.5}, 0)`)
          // Add common class
          .attr('class', getAxisClasses(classes, 'main', styles))
          // Calling corresponding function and adding readable ticks
          .call(yTicks);

      // Line
      // Building the line graph add matching data
      const lineChart: Line<any> = line()
        .x((d: any) => x((d as CardinalChartRowWithNumberOnly).x))
        .y((d: any) => y(d.value));

      // Building SVG
      // Adding axles
      svg.append('g').call(xAxis);
      svg.append('g').call(yAxis);

      // Add a path for the graph
      svg
        .append('path')
        // Position a bit offset to let Y axis a bit of space
        .attr('transform', `translate(${(options?.isRightVerticalAxis ? -1 : 1) * margin * 0.5}, 0)`)
        .attr('class', buildChartClasses(classes, styles))
        // Adding data
        .datum(data)
        // Style the graph
        .attr('fill', 'none')
        .attr('stroke-width', 1.5)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        // Add the path data
        .attr('d', lineChart);

      // Add additional grid lines
      if (options?.showAdditionalGridLines) {
        svg
          .append('g')
          .lower()
          .attr('class', classes.chartGrid)
          .attr('transform', `translate(${(options.isRightVerticalAxis ? 0.5 : 1.5) * margin}, 0)`)
          .call(
            axisLeft(y)
              .ticks(height / 80)
              .tickSize(-width + 2 * margin)
              .tickFormat(() => ''),
          );
      }

      (modifiers || []).forEach((m) => {
        m({
          svg,
          y,
          x,
          data,
          margin,
          styles,
          height,
          width,
          classes,
        });
      });
    }
  }, [data, d3Container, margin, height, width, classes, styles, options, modifiers]);

  return (
    <svg
      ref={d3Container}
      className="d3-component"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
    />
  );
});
