import { type Quota } from '@amalia/amalia-lang/tokens/types';
import {
  type QuotaWithAssignedPlans,
  type SearchQuotasWithAssignedPlansRequest,
} from '@amalia/assignments/quotas/types';
import { http } from '@amalia/core/http/client';
import { type PaginatedResponse } from '@amalia/core/types';

export class QuotasWithAssignedPlansApiClient {
  public static async get(quotaId: Quota['id']) {
    return (await http.get<QuotaWithAssignedPlans>(`/quotas-with-assigned-plans/${quotaId}`)).data;
  }

  public static async search(searchQuotasWithAssignedPlansRequest?: SearchQuotasWithAssignedPlansRequest) {
    return (
      await http.post<PaginatedResponse<QuotaWithAssignedPlans>>(
        '/quotas-with-assigned-plans/searches',
        searchQuotasWithAssignedPlansRequest,
      )
    ).data;
  }
}
