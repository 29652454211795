import { round } from 'lodash';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert } from '@amalia/ext/typescript';
import { type ComputedItem } from '@amalia/payout-calculation/types';

import { type PaymentAmountsByCategory, PaymentCategory } from '../types/payments';
import { type CurrencyAmount } from '../types/statement';

import { formatAmount } from './formatAmount';
import { formatTotal, roundNumber } from './formatTotal';

export const formatValue = (value: number, format?: FormatsEnum, currency?: CurrencySymbolsEnum) => {
  if (format === FormatsEnum.currency) {
    assert(currency, 'Currency needed for formatting value.');
    return formatAmount(value, currency);
  }

  const isPercent = format === FormatsEnum.percent;
  return `${round((isPercent ? 100 : 1) * value, 2)}${isPercent ? ' %' : ''}`;
};

export const formatKpi = (
  variableDefinition: VariableDefinition | null | undefined,
  computedVariable: ComputedItem,
): string => formatValue(computedVariable.value as number, variableDefinition?.format, computedVariable.currency);

export const formatCurrencyAmount = (currencyAmount: CurrencyAmount): string =>
  formatTotal(
    currencyAmount.amount,
    FormatsEnum.currency,
    currencyAmount.currencySymbol,
    currencyAmount.currencyRate,
  ).toString();

/**
 * Function that prints a currency symbol character from a currency symbol
 * i.e. getCurrencySymbolCharacters('EUR') => €
 *
 * @param currencySymbol
 *
 * The trick is to format "0.0" with the formatTotal function, then remove every space, numbers and points just to have the character
 * Adapted from https://stackoverflow.com/questions/50650503/get-the-currency-symbol-for-a-locale
 */
export const getCurrencySymbolCharacters = (currencySymbol = CurrencySymbolsEnum.EUR) =>
  formatTotal(0, FormatsEnum.currency, currencySymbol)
    .toString()
    .replace(/[0-9.]+/gu, '')
    .trim();

export const showPayment = (allAmounts: Partial<PaymentAmountsByCategory>, category: PaymentCategory): boolean => {
  const categoryAmount = allAmounts[category];
  const roundedCategoryAmount = roundNumber(categoryAmount ?? 0);

  // Show future payments if different than 0
  if (category === PaymentCategory.hold) {
    return roundedCategoryAmount !== 0;
  }

  // Show if different than achievement
  if (category === PaymentCategory.paid) {
    const achievedAmount = roundNumber(allAmounts[PaymentCategory.achievement]);
    return roundedCategoryAmount !== achievedAmount;
  }

  return categoryAmount !== undefined;
};

/**
 * Format total amout according to format (%, currency, number).
 * @param value
 * @param totalFormat
 * @param currencySymbol
 * @param currencyRate
 */
export const formatTotalAmount = (
  value: number | null | undefined,
  totalFormat: FormatsEnum,
  currencySymbol?: CurrencySymbolsEnum,
  currencyRate?: number,
): string => {
  const currency = currencySymbol || CurrencySymbolsEnum.EUR;
  switch (totalFormat) {
    case FormatsEnum.number:
      return `${roundNumber(value) || 0}`;
    case FormatsEnum.percent:
      return `${formatTotal(value, FormatsEnum.percent) || 0}`;
    default:
      return formatCurrencyAmount({
        amount: roundNumber(value) || 0,
        currencyRate,
        currencySymbol: currency,
      });
  }
};
