import { http } from '@amalia/core/http/client';
import { type Filter } from '@amalia/payout-definition/plans/types';

export class FiltersApiClient {
  public static async create({
    filter,
    createdFrom,
  }: {
    filter: Partial<Filter>;
    createdFrom?: { ruleId: string; planId: string };
  }): Promise<Filter> {
    const response = await http.post<Filter>('/filters', filter, {
      params: {
        planId: createdFrom?.planId,
        ruleId: createdFrom?.ruleId,
      },
    });
    return response.data;
  }

  public static async patch({ id, filter }: { id: string; filter: Partial<Filter> }): Promise<Filter> {
    const response = await http.patch<Filter>(`/filters/${id}`, filter);
    return response.data;
  }

  public static async delete(filterId: string): Promise<void> {
    await http.delete(`/filters/${filterId}`);
  }

  public static async list(planId?: string): Promise<Filter[]> {
    const { data } = await http.get<Filter[]>('/filters', { params: { planId } });
    return data;
  }

  public static async get(filterId: string): Promise<Filter> {
    const { data } = await http.get<Filter>(`/filters/${filterId}`);
    return data;
  }

  public static async duplicateInNewContext(
    filtersId: string[],
    from: { planId: string; ruleId: string },
  ): Promise<{ isErrorWhileDuplicating: boolean }> {
    const { data } = await http.post<{ isErrorWhileDuplicating: boolean }>('/filters/duplicateInContext', filtersId, {
      params: {
        planId: from.planId,
        ruleId: from.ruleId,
      },
    });
    return data;
  }
}
