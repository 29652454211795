import { partition } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import {
  ButtonLink,
  DropdownList,
  type DropdownListProps,
  type DropdownListItem,
} from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum, subject } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { IconPlanVisibility } from '../plan-visibility/icons/IconPlanVisibility';

export type AssignedPlansDropdownListProps = Omit<DropdownListProps, 'items'> & {
  readonly plans: Pick<Plan, 'archived' | 'id' | 'isHidden' | 'name'>[];
};

export const AssignedPlansDropdownList = memo(function AssignedPlansDropdownList({
  plans,
  children,
  ...props
}: AssignedPlansDropdownListProps) {
  const ability = useAbilityContext();

  const mapPlanToListItem = useCallback(
    (plan: AssignedPlansDropdownListProps['plans'][number]): DropdownListItem => ({
      icon: <IconPlanVisibility isPlanHidden={plan.isHidden} />,
      key: plan.id,
      label: plan.name,
      rightActions: ability.can(ActionsEnum.modify, subject(SubjectsEnum.Plan, plan)) && (
        <ButtonLink
          size={ButtonLink.Size.SMALL}
          variant={ButtonLink.Variant.PRIMARY_TEXT}
          to={
            <Link
              openInNewTab
              to={generatePath(routes.DESIGNER_PLAN, { planId: plan.id })}
            />
          }
        >
          <FormattedMessage defaultMessage="View" />
        </ButtonLink>
      ),
    }),
    [ability],
  );

  const plansListItems = useMemo(() => {
    const [activePlans, archivedPlans] = partition(plans, (plan) => !plan.archived);

    return [
      ...activePlans.map(mapPlanToListItem),
      {
        label: <FormattedMessage defaultMessage="Archived plans" />,
        items: archivedPlans.map(mapPlanToListItem),
        initialIsOpen: false,
      },
    ];
  }, [plans, mapPlanToListItem]);

  return (
    <DropdownList
      {...props}
      disabled={props.disabled || !plansListItems.length}
      items={plansListItems}
    >
      {children}
    </DropdownList>
  );
});
