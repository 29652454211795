import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amalia/design-system/components';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { useUnarchivePlan } from '@amalia/payout-definition/state';

export type UnarchivePlanConfirmModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  readonly plan: Pick<Plan, 'id' | 'name'>;
  readonly onSuccess?: () => void;
};

export const UnarchivePlanConfirmModal = memo(function UnarchivePlanConfirmModal({
  plan,
  isOpen,
  onClose,
  onSuccess,
}: UnarchivePlanConfirmModalProps) {
  const { mutate: unarchivePlan, isPending } = useUnarchivePlan();

  const handleArchivePlan = useCallback(
    () => unarchivePlan(plan.id, { onSuccess }),
    [plan.id, unarchivePlan, onSuccess],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Unarchive plan “{planName}”"
              values={{ planName: plan.name }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to unarchive “<b>{planName}</b>”?{br}{br}Users were unassigned when the plan was archived, so you will have to reassign them."
            values={{ planName: plan.name, b: (chunks) => <strong>{chunks}</strong> }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          isLoading={isPending}
          onClick={handleArchivePlan}
        >
          <FormattedMessage defaultMessage="Unarchive" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
