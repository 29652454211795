import { toError } from '@amalia/ext/typescript';
import { type Filter } from '@amalia/payout-definition/plans/types';
import { FiltersApiClient } from '@amalia/payout-definition/state';

import { addSnackbar } from '../snackbars/actions';
import { type ThunkResult } from '../types';

import { FILTERS_ACTIONS } from './constants';
import { type FiltersSetFiltersAction, type FiltersErrorAction, type FiltersStartAction } from './types';

const filtersStart = (): FiltersStartAction => ({
  type: FILTERS_ACTIONS.START,
});

const filtersError = (error: Error): FiltersErrorAction => ({
  type: FILTERS_ACTIONS.ERROR,
  error,
});

const setFilters = (filters: Filter[]): FiltersSetFiltersAction => ({
  type: FILTERS_ACTIONS.SET_FILTERS,
  payload: { filters },
});

export const fetchFiltersAction =
  (): ThunkResult<Promise<FiltersErrorAction | FiltersSetFiltersAction>> => async (dispatch) => {
    dispatch(filtersStart());
    try {
      const filters = await FiltersApiClient.list();
      return dispatch(setFilters(filters));
    } catch (error) {
      return dispatch(filtersError(toError(error)));
    }
  };

export const duplicateFiltersInNewContextAction =
  (
    filtersId: string[],
    planId: string,
    ruleId: string,
  ): ThunkResult<Promise<FiltersErrorAction | FiltersSetFiltersAction>> =>
  async (dispatch) => {
    dispatch(filtersStart());
    try {
      const { isErrorWhileDuplicating } = await FiltersApiClient.duplicateInNewContext(filtersId, {
        planId,
        ruleId,
      });
      if (isErrorWhileDuplicating) {
        dispatch(
          addSnackbar({
            message: 'Error while importing filter(s). Filter(s) with the same name already exist.',
            options: { variant: 'error' },
          }),
        );
      }
      const filtersUpdated = await FiltersApiClient.list();
      return dispatch(setFilters(filtersUpdated));
    } catch (error) {
      return dispatch(filtersError(toError(error)));
    }
  };
