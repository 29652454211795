import { http } from '@amalia/core/http/client';
import {
  type PaymentContract,
  type ClearOverwriteRequest,
  type Option,
  type PatchPaymentRequest,
  type Payment,
  type PaymentLock,
  type PaginatedResponse,
  isSplit,
  type PaymentSplitPart,
  type CommissionReportFilters,
  type PaymentAggregatedListWithFlag,
} from '@amalia/core/types';

const apiEndpoint = '/payments';

const getMasterPaymentId = (payment: PaymentContract) => payment.masterPaymentId || payment.id;

export class PaymentApiClient {
  public static async getPaymentsForPeriod(
    currentPeriodId?: string,
    filters: CommissionReportFilters = {},
    teamIds?: string[] | null,
    type?: 'commissionReport' | null,
  ): Promise<Partial<PaymentAggregatedListWithFlag>> {
    const { data } = await http.get<Partial<PaymentAggregatedListWithFlag>>(`${apiEndpoint}/amounts`, {
      params: {
        currentPeriodId,
        teamIds,
        ...filters,
        type,
      },
    });

    return data;
  }

  public static async getPaymentPaginate({
    filters,
    teamIds,
    page,
    limit,
    searchValue,
    sortColumn,
    sortOrder,
  }: {
    filters?: CommissionReportFilters;
    teamIds?: string[] | null;
    page: number;
    limit: number;
    searchValue?: string;
    sortColumn?: string;
    sortOrder: 'asc' | 'desc';
  }): Promise<PaginatedResponse<PaymentContract>> {
    const { data } = await http.get<PaginatedResponse<PaymentContract>>(`${apiEndpoint}/paginate`, {
      params: {
        q: searchValue,
        page,
        limit,
        teamIds,
        ...filters,
        ...(sortColumn
          ? {
              sort: sortColumn,
              desc: sortOrder,
            }
          : null),
      },
    });
    return data;
  }

  public static async getPaymentsFacets(
    filters: CommissionReportFilters = {},
    teamIds?: string[] | null,
  ): Promise<{ plans: Option[] }> {
    const { data } = await http.get<{ plans: Option[] }>(`${apiEndpoint}/facets`, {
      params: {
        ...filters,
        teamIds,
      },
    });
    return data;
  }

  public static async patchPayment(paymentId: string, patchRequest: PatchPaymentRequest) {
    const { data } = await http.patch<Payment>(`${apiEndpoint}/${paymentId}`, patchRequest);
    return data;
  }

  public static async clearPaymentOverwrite(paymentId: string, clearOverwriteRequest: ClearOverwriteRequest) {
    const { data } = await http.post<Payment>(`${apiEndpoint}/${paymentId}/clear`, clearOverwriteRequest);
    return data;
  }

  public static async getPaymentLocks() {
    const { data } = await http.get<PaymentLock[]>(`${apiEndpoint}/locks`);
    return data;
  }

  public static async addPaymentLock(paymentLock: PaymentLock) {
    const { data } = await http.put<PaymentLock>(`${apiEndpoint}/locks/${paymentLock.periodId}`);
    return data;
  }

  public static async removePaymentLock(paymentLock: PaymentLock) {
    await http.delete(`${apiEndpoint}/locks/${paymentLock.periodId}`);
  }

  public static async getPaymentsDetails(payment: PaymentContract): Promise<PaymentContract[]> {
    if (!isSplit(payment)) {
      return [payment];
    }

    const { data } = await http.get<PaymentContract[]>(`/payments/split/${getMasterPaymentId(payment)}`);
    return data;
  }

  public static async removeSplit(payment: PaymentContract): Promise<void> {
    if (!isSplit(payment)) {
      return;
    }
    await http.delete(`/payments/split/${getMasterPaymentId(payment)}`);
  }

  public static async addSplit(payment: PaymentContract, splitParts: PaymentSplitPart[]): Promise<void> {
    if (isSplit(payment)) {
      return;
    }
    await http.post(`/payments/split/${getMasterPaymentId(payment)}`, { splitParts });
  }
}
