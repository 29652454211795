import { type FiltersType, type PaginatedQuery } from '@amalia/core/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export const customObjectsQueryKeys = {
  all: () => ['custom-objects'] as const,
  ofCustomObjectDefinition: {
    all: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [...customObjectsQueryKeys.all(), 'of-definition', objectDefinitionMachineName] as const,
    page: (
      objectDefinitionMachineName: CustomObjectDefinition['machineName'],
      pagination: PaginatedQuery,
      filters?: FiltersType[] | null,
      overwrite?: boolean,
      // Refresh the data when the last import is updated.
      lastImportUpdate?: Date,
    ) =>
      [
        ...customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionMachineName),
        'page',
        pagination,
        filters,
        overwrite,
        lastImportUpdate,
      ] as const,
  } as const,
} as const;

export const customObjectsMutationKeys = {
  all: () => ['custom-objects'] as const,
  ofCustomObjectDefinition: {
    all: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [...customObjectsMutationKeys.all(), 'of-definition', objectDefinitionMachineName] as const,
    purge: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [...customObjectsMutationKeys.ofCustomObjectDefinition.all(objectDefinitionMachineName), 'purge'] as const,
    patch: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [...customObjectsMutationKeys.ofCustomObjectDefinition.all(objectDefinitionMachineName), 'patch'] as const,
    clear: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [...customObjectsMutationKeys.ofCustomObjectDefinition.all(objectDefinitionMachineName), 'clear'] as const,
    bulkOverwrite: (objectDefinitionMachineName: CustomObjectDefinition['machineName']) =>
      [
        ...customObjectsMutationKeys.ofCustomObjectDefinition.all(objectDefinitionMachineName),
        'bulk-overwrite',
      ] as const,
  } as const,
} as const;
