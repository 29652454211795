import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type ReduxAction } from '../types';

import { CUSTOM_OBJECT_DEFINITIONS_ACTIONS } from './constants';
import { type CustomObjectDefinitionsReducer } from './types';

export const customObjectDefinitionsReducer = combineReducers<CustomObjectDefinitionsReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.START:
        return state + 1;
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.ERROR:
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS:
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state = null, action) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS:
        return keyBy(action.payload.customObjectDefinitions, 'machineName');
      default:
        return state;
    }
  },
  relationshipsMap: (state = null, action) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS:
        return keyBy(action.payload.relationships, 'name');
      default:
        return state;
    }
  },
});
