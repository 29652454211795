import { uniq } from 'lodash';

import { toError } from '@amalia/ext/typescript';
import { UserApiClient } from '@amalia/tenants/users/api-client';
import { type UserContract } from '@amalia/tenants/users/types';

import { type ThunkResult } from '../types';

import { USERS_ACTIONS } from './constants';
import { selectUsersList, selectUsersMap } from './selectors';
import { type UsersSetUsersAction, type UsersErrorAction, type UsersStartAction } from './types';

const usersStart = (): UsersStartAction => ({
  type: USERS_ACTIONS.START,
});

const usersError = (error: Error): UsersErrorAction => ({
  type: USERS_ACTIONS.ERROR,
  error,
});

const setUsers = (users: UserContract[], allUsers?: boolean, activeUsers?: boolean): UsersSetUsersAction => ({
  type: USERS_ACTIONS.SET_USERS,
  payload: { users, allUsers, activeUsers },
});

export const fetchUsers =
  (userIds: string[]): ThunkResult<Promise<UsersErrorAction | UsersSetUsersAction>> =>
  async (dispatch, getState) => {
    const currentUsersMap = selectUsersMap(getState());
    const cachedUserIds = Object.keys(currentUsersMap);

    const usersToFetch = userIds.filter((userId) => !cachedUserIds.includes(userId));

    if (usersToFetch.length === 0 && cachedUserIds.length > 0) {
      return setUsers(Object.values(currentUsersMap));
    }

    dispatch(usersStart());

    try {
      const fetchedUsers = await UserApiClient.fetchUsersByIds(uniq(usersToFetch));
      // We need to pass already fetched users as some components need the whole list
      return dispatch(setUsers([...Object.values(currentUsersMap), ...fetchedUsers]));
    } catch (error) {
      return dispatch(usersError(toError(error)));
    }
  };

export const fetchAllUsers =
  (onlyActive: boolean): ThunkResult<Promise<UsersErrorAction | UsersSetUsersAction>> =>
  async (dispatch, getState) => {
    const state = getState();

    // Check the cache before query.
    if (
      // If all users are already loaded.
      state.users.allUsersLoaded ||
      // Or if you're querying for active, but they're all there.
      (onlyActive && state.users.allActiveUsersLoaded)
    ) {
      return setUsers(selectUsersList(state));
    }

    dispatch(usersStart());

    try {
      if (onlyActive) {
        const activeUsers = await UserApiClient.fetchActiveUsers();
        return dispatch(setUsers(activeUsers, false, true));
      }

      const allUsers = await UserApiClient.getEmployees();
      return dispatch(setUsers(allUsers, true, false));
    } catch (error) {
      return dispatch(usersError(toError(error)));
    }
  };
