import { type Property } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

import { type Option } from '../types/options';

export const getFieldAsOption = (definition: Pick<CustomObjectDefinition, 'properties'>, fieldName: string) => {
  const property = definition.properties[fieldName];
  return property
    ? {
        label: property.name,
        name: property.machineName,
      }
    : null;
};

export const transformFieldToOption = (field: Property): Option => ({ value: field.machineName, label: field.name });
