import { intersection, uniq } from 'lodash';

import { ensureArray } from '@amalia/core/types';
import { type AuthenticatedContext } from '@amalia/kernel/auth/types';
import { UserRole } from '@amalia/tenants/users/types';

/**
 * @deprecated -- Call authenticated context directly or refactor with subsets.
 * @param authenticatedContext
 * @param userIds
 */
export const filterMyManageesScope = (authenticatedContext: AuthenticatedContext, userIds: string[]): string[] => {
  const userIdsToQuery: string[] = ensureArray(userIds).length > 0 ? ensureArray(userIds) : [];

  if ([UserRole.ADMIN, UserRole.READ_ONLY_ADMIN].includes(authenticatedContext.user.role)) {
    return userIdsToQuery;
  }

  const userIdsInScope = authenticatedContext.hierarchy
    .getSubordinates(new Date())
    .map((ta) => ta.user.id)
    .concat(authenticatedContext.user.id);

  // if userIdsInScope is empty array, it means user has access to all users
  // we need to skip intersection when no filtering on users ids is present
  return uniq(userIdsToQuery.length > 0 ? intersection(userIdsToQuery, userIdsInScope) : userIdsInScope);
};
