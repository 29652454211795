import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const userProfilesAbilityDefinitions = {
  ADMIN({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.UserProfile);
    can(ActionsEnum.invite, SubjectsEnum.UserProfile);

    can(
      ActionsEnum.toggle_deactivation,
      SubjectsEnum.UserProfile,
      // Can't deactivate themselves
      ({ userId }: { userId: string }) => user.id !== userId,
    );
  },

  READ_ONLY_ADMIN({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      predicate: () => true,
      subset: SubsetAccessEnum.EVERYTHING,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },

  FINANCE({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },

  MANAGER({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) => userId === user.id || hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },

  READ_ONLY_MANAGER({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) => userId === user.id || hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },

  EMPLOYEE({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) => userId === user.id || hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },

  READ_ONLY_EMPLOYEE({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) => userId === user.id || hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });
  },

  DEACTIVATED_USER({ user }, { can }) {
    can(
      ActionsEnum.view,
      SubjectsEnum.UserProfile,
      /* can view their own profile */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
