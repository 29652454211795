import { useQuery } from '@tanstack/react-query';
import { type SetRequired } from 'type-fest';

import { type Statement } from '@amalia/core/types';
import { type ColumnSortingState } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { type Dataset } from '@amalia/payout-calculation/types';

import { StatementDatasetsApiClient } from '../api-client/statement-datasets.api-client';

export type UseDatasetProps = {
  statementId?: Statement['id'];
  datasetId?: Dataset['id'];
  currentPage: number;
  searchValue?: string;
  pageSize: number;
  forecasted: boolean;
  columnSorting: ColumnSortingState;
};

export const statementDatasetsQueryKeys = {
  all: () => ['statement-datasets'] as const,
  details: ({
    statementId,
    datasetId,
    currentPage,
    searchValue,
    pageSize,
    forecasted,
    columnSorting,
  }: SetRequired<UseDatasetProps, 'datasetId' | 'statementId'>) =>
    [
      ...statementDatasetsQueryKeys.all(),
      statementId,
      datasetId,
      currentPage,
      searchValue,
      pageSize,
      forecasted,
      columnSorting,
    ] as const,
} as const;

export const useStatementDataset = ({
  statementId,
  datasetId,
  currentPage,
  searchValue,
  pageSize,
  forecasted,
  columnSorting,
}: UseDatasetProps) =>
  useQuery({
    queryKey: statementDatasetsQueryKeys.details({
      statementId: statementId!,
      datasetId: datasetId!,
      currentPage,
      searchValue,
      pageSize,
      forecasted,
      columnSorting,
    }),
    queryFn: () => {
      assert(statementId, 'Statement ID is required');
      assert(datasetId, 'Dataset ID is required');
      return StatementDatasetsApiClient.fetchPaginatedDatasetRows(
        statementId,
        datasetId,
        {
          // Datagrid counts pages starting at 0, but API starts at 1.
          page: currentPage + 1,
          search: searchValue !== '' ? searchValue : undefined,
          limit: pageSize,
          sort: columnSorting[0]?.id,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          desc: columnSorting[0]?.direction ? columnSorting[0].direction === 'desc' : undefined,
        },
        forecasted,
      );
    },
    enabled: !!statementId && !!datasetId,
  });
