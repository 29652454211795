import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { isConflictError } from '@amalia/core/http/client';
import { getSupportedPlanFrequencies } from '@amalia/core/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { type MergeAll } from '@amalia/ext/typescript';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { useCreatePlan } from '@amalia/payout-definition/state';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

type CreatePlanFormValues = {
  name: string;
  frequency: PeriodFrequencyEnum;
};

type CreatePlanFormikProps = FormikFormProps<CreatePlanFormValues, Plan>;

export type CreatePlanFormProps = MergeAll<
  [
    Omit<CreatePlanFormikProps, 'initialValues' | 'onSubmit' | 'validationSchema'>,
    {
      duplicateOf?: {
        id: string;
        name: string;
      };
    },
  ]
>;

export const CreatePlanForm = memo(function CreatePlanForm({ duplicateOf, ...props }: CreatePlanFormProps) {
  const { formatMessage } = useIntl();
  const { data: company } = useCurrentCompany();
  const { mutateAsync: createPlan } = useCreatePlan();

  const defaultFrequency = getSupportedPlanFrequencies(company)[0];

  const initialValues: CreatePlanFormValues = useMemo(
    () => ({
      name: duplicateOf?.name || '',
      frequency: defaultFrequency,
    }),
    [duplicateOf, defaultFrequency],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().min(2).max(100).required(),
        frequency: Yup.string().oneOf(getSupportedPlanFrequencies(company)).required(),
      }),
    [company],
  );

  const handleSubmit: Required<CreatePlanFormikProps>['onSubmit'] = useCallback(
    async (values: CreatePlanFormValues, { setFieldError }) => {
      try {
        return await createPlan(values);
      } catch (err) {
        if (isConflictError(err)) {
          setFieldError('name', formatMessage({ defaultMessage: 'A plan with this name already exists.' }));
        }

        // Throw for FormikForm.
        throw err;
      }
    },
    [createPlan, formatMessage],
  );

  return (
    <FormikForm
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    />
  );
});
