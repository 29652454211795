import { Form } from 'formik';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormikInput, Modal, type ModalProps } from '@amalia/design-system/components';

import { DuplicatePlanForm, type DuplicatePlanFormProps } from '../DuplicatePlanForm';

export type DuplicatePlanFormModalProps = DuplicatePlanFormProps & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const DuplicatePlanFormModal = memo(function DuplicatePlanFormModal({
  isOpen,
  onClose,
  ...props
}: DuplicatePlanFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <DuplicatePlanForm {...props}>
        {({ isValid, isSubmitting }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage
                    defaultMessage="Duplicate plan “{planName}”"
                    values={{ planName: props.plan.name }}
                  />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="New plan name" />}
                  name="name"
                />
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Confirm" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </DuplicatePlanForm>
    </Modal>
  );
});
